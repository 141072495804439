import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import Select from "react-select";

import { getCountries } from "../../common/redux/actions/CountriesActions";
import { reactSelectDropdownStyle } from "../../common/styles/dropdowns";

const CountrySelect = (props, context) => {
  const dispatch = useDispatch();

  const countries = useSelector((state) => state.CountriesState);
  const { initialValue, isMulti, viewOnly, isDisabled } = props;

  const [countryOptions, setCountryOptions] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(initialValue);

  useEffect(() => {
    !countries.fetched && dispatch(getCountries());
  }, []);

  useEffect(() => {
    setSelectedCountry(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const countryOptions = Object.keys(countries.items)
      .sort((a, b) =>
        countries.items[a].name > countries.items[b].name ? 1 : -1
      )
      .map((key) => {
        return {
          value: countries.items[key].country,
          label: countries.items[key].name,
        };
      });

    setCountryOptions(countryOptions);
  }, [countries]);

  const handleCountryChange = (data, event) => {
    const value = data
      ? isMulti
        ? data.map((country) => country.value)
        : data.value
      : isMulti
      ? []
      : null;
    setSelectedCountry(value);
    if (props.onChange) {
      props.onChange(value);
    }
  };

  const getCountryNames = () => {
    if (!countries.items || selectedCountry?.length === 0) return [];
    return selectedCountry
      .map((countryCode) => {
        const country = Object.values(countries.items).find(
          (item) => item.country === countryCode
        );
        return country ? country.name : null;
      })
      .filter((name) => name);
  };

  const countryNames = viewOnly ? getCountryNames() : undefined;

  return (
    <Fragment>
      {viewOnly === true ? (
        <Fragment>
          <p>
            <strong>
              {props.placeholder}&nbsp;
              <span className="significant">{countryNames?.join(", ")}</span>
            </strong>
          </p>
        </Fragment>
      ) : (
        <Select
          name="country"
          options={countryOptions}
          value={
            isMulti
              ? countryOptions.filter(
                  (o) => selectedCountry?.indexOf(o.value) >= 0
                )
              : countryOptions.filter((o) => o.value === selectedCountry)[0] ||
                null
          }
          placeholder={props.placeholder}
          style={{ textAlign: "center" }}
          onChange={handleCountryChange}
          styles={reactSelectDropdownStyle}
          isSearchable={props.isSearchable}
          isMulti={isMulti}
          isClearable={props.isClearable}
          isDisabled={isDisabled}
        />
      )}
    </Fragment>
  );
};

CountrySelect.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default CountrySelect;
