import React from 'react'
import { useSelector } from "react-redux";
import { Container } from 'react-bootstrap';
import MarketPlaceMenu from './MarketPlaceMenu'
import HeroImage from '../../components/HeroImage';
import AccountNavButton from '../../components/AccountNavButton';
import NotificationsModal from '../Jobseekers/NotificationsModal';
import JobsAlertModal from '../Jobs/JobsAlertModal';
import BaseView from '../BaseView';
import PropTypes from 'prop-types';

const MarketPlaceHomeView = (props, context) => {

  const language = useSelector(state => state.i18nState.lang)

  const getTitleImage = () => {
    switch (language) {
      case 'mm-z':
        return '/static/media/burmese-title.png'
      case 'mm-u':
        return '/static/media/burmese-title.png'
      case 'kh':
        return '/static/media/khmer-title.png'
      case 'ne':
        return '/static/media/nepal-title.png'
      default:
        return '/static/media/eng-title.png'
    }
  }


  const getBackgroundImage = () => {
    switch (language) {
      case 'mm-z':
        return '/static/media/burmese-bg.jpg'
      case 'mm-u':
        return '/static/media/burmese-bg.jpg'
      case 'kh':
        return '/static/media/khmer-bg.jpg'
      case 'ne':
        return '/static/media/nepal-bg.jpeg'
      default:
        return '/static/media/burmese-bg.jpg'
    }
  }


  return (
    <BaseView backurl='/' title={context.t('Marketplace')}>
      <Container fluid={true} style={{ padding: 0, margin: 0 }}>
        <HeroImage height='180px' imageURL={getBackgroundImage()}>
          <img src={getTitleImage()} style={{ width: 200, marginTop: '-12px' }} alt='' />
        </HeroImage>
        <AccountNavButton />
        <NotificationsModal show={false}/>
        <MarketPlaceMenu language={language} />
        <JobsAlertModal />
      </Container>
    </BaseView>
  );
}

export default MarketPlaceHomeView;


MarketPlaceHomeView.contextTypes = {
  t: PropTypes.func.isRequired
}