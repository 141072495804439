import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { Provider } from "react-redux";
import { createStore, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import I18n from "redux-i18n";
import Maintenance from "./components/Maintenance";

// Import the new dependency
import { QueryClient, QueryClientProvider } from "react-query";

import CommonReducer from "./common/redux/CommonReducer";
import { translations } from "./common/i18n/translations";
import { updateServiceWorker } from "./common/redux/actions/ServiceWorkerActions";

import App from "./App";
import ScrollToTop from "./components/ScrollToTop";
import * as serviceWorker from "./serviceWorker";
import history from "./history";
import "./index.css";

const composeEnhances = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  CommonReducer,
  composeEnhances(applyMiddleware(thunk))
);

// Maintenance mode on or off
const maintenance = process.env.REACT_APP_JOBSEEKER_MAINTENANCE
  ? process.env.REACT_APP_JOBSEEKER_MAINTENANCE
  : false;

// Create a new QueryClient instance
const queryClient = new QueryClient();

if (maintenance === "true") {
  ReactDOM.render(<Maintenance />, document.getElementById("root"));
} else {
  ReactDOM.render(
    <Provider store={store}>
      <I18n
        translations={
          process.env.REACT_APP_DISABLE_TRANSLATIIONS === "true"
            ? {}
            : translations
        }
      >
        {/* Wrap the App component with QueryClientProvider */}
        <QueryClientProvider client={queryClient}>
          <Router history={history}>
            <ScrollToTop />
            <App />
          </Router>
        </QueryClientProvider>
      </I18n>
    </Provider>,
    document.getElementById("root")
  );
}

serviceWorker.register({
  // onSuccess: () => alert('successfuly installed'),
  onUpdate: (reg) => store.dispatch(updateServiceWorker(reg)),
});

// register firebase service worker
// if ("serviceWorker" in navigator) {
//   navigator.serviceWorker
//     .register('firebase-messaging-sw.js')
//     .then(function(registration) {
//       console.log("FCM service worker registration successful, scope is:", registration.scope);
//     })
//     .catch(function(err) {
//       console.log("FCM service worker registration failed, error:", err);
//     });
// }
