// styles.js
const styles = {
  commentContainer: {
    marginBottom: "5px",
    border: "1px solid #ddd",
    boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.1)",
    padding: "10px",
    borderRadius: "4px",
    borderBottomLeftRadius: "20px",
    borderTopRightRadius: "20px",
    borderTopLeftRadius: "20px",
  },
  rowdesign: {
    marginTop: "0.5rem",
    color: "black",
  },
  senderavatar: {
    width: "45px",
    height: "45px",
    borderRadius: "100%",
    border: "1px solid #e9ecef",
  },
  commentreply: {
    display: "flex",
    justifyContent: "space-between",
  },
  iconstyle: {
    color: "#198474",
    marginLeft: 4,
  },
  commenttext: {
    marginTop: "-0.5rem",
    marginBottom: 0,
    wordBreak: "break-word",
  },
  commentreplyicons: {
    backgroundColor: "#fff",
    border: "1px solid gray",
    borderRadius: "5px",
    padding: "5px",
    margin: "-40px 0 5px 0",
  },
};

export default styles;
