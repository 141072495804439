import React from "react";
import { Jumbotron } from 'react-bootstrap';


const HeroImage = (props) => {
  return (
    <Jumbotron className='Hero afterTopNavigationBar'
      style={{backgroundImage: props.imageURL? `url(${props.imageURL})` : null, height:`${props.height}`}}
    >
      {props.children}
    </Jumbotron>
  )
}


export default HeroImage;
