import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";

import { Row, Col, Image } from 'react-bootstrap';

import { updateProfile } from '../common/redux/actions/ProfileActions';


export default function ChooseLanguageView(props) {
  const dispatch = useDispatch()

  const languagesArray = useSelector(state => state.LanguagesState.itemsArray);
  const profile = useSelector(state => state.ProfileState.data);

  const chooseLanguage = event => {
    const preferredLanguage = event.target.getAttribute('name').toLowerCase()
    const preferredLanguageId = event.target.getAttribute('languageid')
    const recentPage = localStorage.getItem('recentPage') ? localStorage.getItem('recentPage') : localStorage.getItem('landedUrl')

    if (profile.id) {
      dispatch(updateProfile(profile.type, profile.id, { preferred_language: preferredLanguageId })).finally(() => {
        window.location.href = recentPage
      })
    } else {
      localStorage.setItem('language', preferredLanguage)
      window.location.href = recentPage
    }
  }


  return (
    <Row style={{ margin: 'auto', wordBreak: 'break-word' }}>
      {[...languagesArray].filter(language => language.code !== 'hi' && language.code !== 'th' && language.code != 'ms').sort((a, b) => a.code < b.code ? -1 : 1).map((item, index) => {
        return (
          <Col key={index} xs={6} className='mt-3' name={item.code} languageid={item.id} onClick={chooseLanguage}>
            <Image src={item.flag}
               style={{ width: '64px', height: '64px', objectFit: 'contain'}} 
               name={item.code} languageid={item.id} 
               crossOrigin='anonymous' />
            <p style={{ marginBottom: 0, fontWeight: 600 }} className='mt-2' name={item.code} languageid={item.id}> {item.name} </p>
          </Col>
        )
      })}
    </Row>
  )
}