import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";

import { Container, Col, Image, Button } from 'react-bootstrap';


export default function FilterResultsNotFound(props, context) {
  const dispatch = useDispatch()

  return (
    <Container className='mt-5'>
      <Image
        src={'/static/media/sad.png'}
        crossOrigin='anonymous'
        style={{width:'45px', height:'auto'}}
      />
      <p className='mt-2' style={{marginBottom: 0}}> {props.text} </p>
    </Container>
  );
}

FilterResultsNotFound.contextTypes = {
  t: PropTypes.func.isRequired
}
