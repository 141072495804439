import React from "react";
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';

export default function WorkingExperienceRemoveConfirmationModal(props, context) {

  return (
       <>
          <Modal show={props.show} centered onHide={props.handleClose}>
              <Modal.Header closeButton>
                      <Modal.Title style={{fontSize:'1rem'}}> <b> {context.t('Confirmation !')}</b></Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  {context.t('Are you sure you want to remove ?')}
              </Modal.Body>
              <Modal.Footer>
                    <Button variant="secondary" onClick={props.handleClose}>
                      {context.t('Cancel')}
                    </Button>
                    <Button variant="success" onClick={props.handleRemoveWorkingExperience.bind(this,props.index)}>
                      {context.t('Yes, Sure')}
                    </Button>
              </Modal.Footer>
          </Modal>
       </>
  )
}

WorkingExperienceRemoveConfirmationModal.contextTypes = {
  t: PropTypes.func.isRequired
}
