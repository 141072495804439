import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";

import { Container, Row, Col, Modal, Button } from 'react-bootstrap';
import BaseView from '../BaseView';
import { getStaticPages } from '../../common/redux/actions/StaticPagesActions';


export default function PrivacyPolicyModal(props, context) {
  const dispatch = useDispatch()

  const staticPages = useSelector(state => state.StaticPagesState);
  const [privacyPolicy, setPrivacyPolicy] = useState(null)

  useEffect(() => {
    if (!staticPages.fetched) {
      dispatch(getStaticPages())
    }
  }, []);

  useEffect(() => {
    if (staticPages.fetched) {
      const policy = staticPages.items['privacy_policy']
      setPrivacyPolicy(policy)
    }
  }, [staticPages.items]);

  return (
    <Modal centered show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>
          <Modal.Title style={{fontSize:'1rem'}}> <b>{context.t("Privacy Policy")}</b> </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{display: 'flex', justifyContent: 'center'}}>
        {privacyPolicy &&
          <div className='description' dangerouslySetInnerHTML={{__html: privacyPolicy.content}}/>
        }
      </Modal.Body>
      <Modal.Footer style={{flexDirection: 'column'}}>
        <Button variant='outline-secondary' onClick={props.onHide}> {context.t('Close')} </Button>
      </Modal.Footer>
    </Modal>
  );
}

PrivacyPolicyModal.contextTypes = {
  t: PropTypes.func.isRequired
}
