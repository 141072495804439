import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import BaseView from "../BaseView";
import NotificationsListItemV2 from "../../components/NotificationsListItemV2";
import { getProfile } from "../../common/redux/actions/ProfileActions";
import FilterResultsNotFound from "../../components/FilterResultsNotFound";

export default function NotificationsView(props, context) {
  const dispatch = useDispatch();

  const profile = useSelector((state) => state.ProfileState.data);
  const profileState = useSelector((state) => state.ProfileState);
  const userId = useSelector((state) => state.AuthenticationState.id);

  const [notifications, setNotifications] = useState([]);
  const [loadingNotifications, setLoadingNotifications] = useState(false);

  useEffect(() => {
    if (!profileState.fetched && !profileState.loading && userId) {
      dispatch(getProfile("JS", userId));
    }
  }, [dispatch, userId, profileState.fetched, profileState.loading]);

  useEffect(() => {
    if (profile.id) {
      setLoadingNotifications(true);
      axios
        .get(`${process.env.REACT_APP_API_URL}/notifications/`)
        .then((res) => {
          if (res.data) {
            setNotifications(res.data);
          }
        })
        .catch((error) => {
          console.error("Failed to fetch notifications", error);
        })
        .finally(() => {
          setLoadingNotifications(false);
        });
    }
  }, [profile]);

  return (
    <BaseView title={context.t("Notifications")} backurl="/">
      <hr className="afterTopNavigationBarWithoutFilters"></hr>
      {profileState.loading || loadingNotifications ? (
        <div style={{ display: "flex", height: "calc(100% - 118px)" }}>
          <img
            src={"/static/media/spinner.png"}
            alt="Loading"
            className="LoadingSpinner"
          />
        </div>
      ) : (
        <>
          {notifications && notifications.length > 0 ? (
            <div>
              {notifications.map((notification) => (
                <NotificationsListItemV2
                  key={notification.id}
                  notification={notification}
                />
              ))}
            </div>
          ) : (
            <FilterResultsNotFound text={context.t("No notifications")} />
          )}
        </>
      )}
    </BaseView>
  );
}

NotificationsView.contextTypes = {
  t: PropTypes.func.isRequired,
};
