import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

//import { connect } from 'react-redux';
import { NavLink } from "react-router-dom";
import { Container, Image, Button } from "react-bootstrap";
//import Tabs from 'react-bootstrap/Tabs'
//import Tab from 'react-bootstrap/Tab'

import { Online } from "react-detect-offline";
import Rater from "react-rater";
import "react-rater/lib/react-rater.css";

import BaseView from "../BaseView";
import Address from "../../components/Address";
import CompanyContacts from "../../components/CompanyContacts";
import Comment from "../../components/Comment";
import CommentInput from "../../components/CommentInput";
import Rating from "../../components/Rating";
import DetailedRating from "../../components/DetailedRatings";
import ShareButtons from "../../components/ShareButtons";
import { addDefaultImageSrc } from "../../common/utilities/utilities";
import { postComment } from "../../common/redux/actions/CommentsActions";
import { getCountries } from "../../common/redux/actions/CountriesActions";
import { getCities } from "../../common/redux/actions/CitiesActions";
import { getStates } from "../../common/redux/actions/StatesActions";
import { getServiceCategories } from "../../common/redux/actions/ServiceCategoriesActions";
import { getServiceProviders } from "../../common/redux/actions/ServiceProvidersActions";

export default function ServiceProvidersDetailedView(props, context) {
  const dispatch = useDispatch();

  const cities = useSelector((state) => state.CitiesState);
  const states = useSelector((state) => state.StatesState);
  const countries = useSelector((state) => state.CountriesState);

  const language = useSelector((state) => state.i18nState.lang);
  const profile = useSelector((state) => state.ProfileState);

  const serviceCategories = useSelector(
    (state) => state.ServiceCategoriesState
  );
  const serviceProviders = useSelector((state) => state.ServiceProvidersState);

  const [serviceProvider, setServiceProvider] = useState(null);
  const [serviceProviderCategories, setServiceProviderCategories] = useState(
    []
  );
  const [showRateForm, setShowRateForm] = useState(false);

  const [commentReply, setCommentReply] = useState({
    show: false,
    parent: null,
    base_parent: null,
    username: "",
    isModerator: false,
  });

  useEffect(() => {
    !cities.fetched && dispatch(getCities());
    !states.fetched && dispatch(getStates());
    !countries.fetched && dispatch(getCountries());
    !serviceCategories.fetched && dispatch(getServiceCategories());
    !serviceProviders.fetched && dispatch(getServiceProviders());
  }, []);

  useEffect(() => {
    if (serviceProviders.fetched) {
      const serviceProviderId = parseInt(props.match.params.id);

      const matchedServiceProvider = serviceProviders.items.filter(
        (item) => item.id === serviceProviderId
      )[0];
      if (matchedServiceProvider) {
        setServiceProvider(matchedServiceProvider);
      } else {
        props.history.push("/not-found");
      }
    }
  }, [serviceProviders.items]);

  useEffect(() => {
    if (serviceProvider && serviceCategories.fetched) {
      let serviceProviderCategories = new Set();
      serviceProvider.service_categories.map((category) =>
        serviceProviderCategories.add(
          serviceCategories.items[category] &&
            serviceCategories.items[category].name
        )
      );
      setServiceProviderCategories(serviceProviderCategories);
    }
  }, [serviceProvider, serviceCategories.items]);

  const handleSubmitComment = (payload) => {
    dispatch(postComment("service_provider", payload));
  };

  const handleClickReplyButton = (replyPayload) => {
    setCommentReply({
      ...replyPayload,
      show: true,
    });
  };

  const handleCloseReply = () => {
    setCommentReply({
      show: false,
      parent: null,
      base_parent: null,
      username: "",
      isModerator: false,
    });
  };

  return (
    <BaseView title={context.t("Service Providers")} backurl="/services">
      {cities.loading ||
      states.loading ||
      countries.loading ||
      serviceCategories.loading ||
      serviceProviders.loading ? (
        <div style={{ display: "flex", height: "100%" }}>
          <img
            src={"/static/media/spinner.png"}
            alt=""
            className="LoadingSpinner"
          />
        </div>
      ) : (
        serviceProvider && (
          <Container className="afterTopNavigationBar" style={{ padding: 0 }}>
            <Image
              src={
                serviceProvider.image
                  ? serviceProvider.image
                  : "/static/media/service.png"
              }
              crossOrigin="anonymous"
              style={{ width: "150px", marginTop: "1rem" }}
              onError={addDefaultImageSrc}
            />
            <Container
              className="pb-6"
              style={{ textAligh: "left !important" }}
            >
              <Rater
                total={5}
                rating={serviceProvider.rating_score}
                interactive={false}
              />
              <span>
                {" "}
                {serviceProvider.rating_score &&
                  serviceProvider.rating_score.toFixed(1)}{" "}
              </span>
              <span
                style={{
                  position: "relative",
                  bottom: "7px",
                  fontSize: "0.7em",
                }}
              >
                ({serviceProvider.rating_count})
              </span>
              <h3 className="ListItemName"> {serviceProvider.name} </h3>
              {language !== "en" && (
                <h5 className="ListItemName"> {serviceProvider.name_en} </h5>
              )}

              {/*{countries.size > 0 &&
                <p style={{margin:0}}> {[...countries].join(', ')} </p>
              } */}
              {serviceProvider.service_categories && (
                <p
                  style={{ fontWeight: 600, fontSize: "11px", marginBottom: 0 }}
                >
                  {" "}
                  {[...serviceProviderCategories].join(", ")}{" "}
                </p>
              )}
              <div className="description">
                <p> {serviceProvider.description} </p>
                <CompanyContacts
                  website={serviceProvider.website}
                  email={serviceProvider.email}
                  phone_numbers={serviceProvider.phone_numbers}
                  facebook={serviceProvider.facebook}
                  viber={serviceProvider.viber}
                  line={serviceProvider.line}
                />
              </div>
              {/* <Tabs defaultActiveKey="Address">
                <Tab eventKey="Address" title={context.t('Address')}> */}
              {serviceProvider.addresses.length > 0 ? (
                <div>
                  {serviceProvider.addresses.map((address, i) => (
                    <Address
                      key={i}
                      name_en={serviceProvider.name_en} // used in a google maps search
                      street={address.street}
                      city={address.city_id}
                      state={address.state_id}
                      country={address.country_id}
                      zip={address.zip}
                      addressContacts={address.contacts}
                    />
                  ))}
                </div>
              ) : (
                <p className="mt-3">
                  {" "}
                  {context.t("No Focal Person contact provided")}{" "}
                </p>
              )}
              {/* </Tab> */}
              <div className="mt-3">
                <ShareButtons title={serviceProvider.name} />
              </div>
              <hr />
              {/* <Tab eventKey="Rate" title={context.t('Rating')}> */}
              <p className="rated-by-text">
                {context.t("Rated by ({number}) viewers", {
                  number: serviceProvider.rating_count,
                })}
                &nbsp;&nbsp;<i class="fa-regular fa-circle-check"></i>
              </p>
              {profile.data && profile.data.type === "JS" && (
                <div className="NavigationBar-icon">
                  <i
                    className={`mt-4 far fa-${
                      showRateForm ? "eye" : "edit"
                    } fa-2x`}
                    style={{ color: "#198474" }}
                    onClick={() => setShowRateForm(!showRateForm)}
                  ></i>
                  <span>
                    {" "}
                    {showRateForm
                      ? context.t("View rating")
                      : context.t("Give rating")}{" "}
                  </span>
                </div>
              )}
              {showRateForm ? (
                <Rating
                  group={serviceProvider.rating_group_name}
                  object_id={serviceProvider.id}
                  content_type="service provider"
                  userRating={
                    serviceProvider.user_ratings &&
                    serviceProvider.user_ratings[0]
                  }
                  onSubmit={() => setShowRateForm(false)}
                />
              ) : (
                <DetailedRating
                  overall_score={serviceProvider.rating_score}
                  scores_for_each_criteria={
                    serviceProvider.scores_for_each_criteria
                  }
                />
              )}
              {/* </Tab> */}
              <hr />
              {/* <Tab eventKey='Comments' title={context.t('Comments')}> */}
              {serviceProvider.comments.length > 0 && (
                <div className="CommentThread">
                  {serviceProvider.comments
                    .sort(
                      (a, b) => new Date(b.created_at) - new Date(a.created_at)
                    )
                    .map((comment, index) => {
                      return (
                        <Comment
                          key={index}
                          comment={comment}
                          onClickReply={handleClickReplyButton}
                          onSubmitReply={handleSubmitComment}
                        />
                      );
                    })}
                </div>
              )}
              <Online>
                {profile.data && profile.data.id ? (
                  <CommentInput
                    user_id={profile.data.id}
                    object_id={serviceProvider.id}
                    parent={commentReply.parent}
                    base_parent={commentReply.base_parent}
                    isReply={commentReply.show}
                    onSubmit={handleSubmitComment}
                    onCloseReply={handleCloseReply}
                    replyUsername={commentReply.username}
                    replyIsModerator={commentReply.isModerator}
                  />
                ) : (
                  <div className="mt-3">
                    <NavLink to="/login">
                      {" "}
                      {context.t("Please login to write a comment..")}{" "}
                    </NavLink>
                  </div>
                )}
              </Online>
              {/* </Tab>
            </Tabs> */}
              {profile.data && profile.data.type === "IS" && (
                <Button
                  href={`${process.env.REACT_APP_SERVER_URL}/admin/service_providers/serviceprovider/${serviceProvider.id}/change`}
                  target="_blank"
                  className="mt-3"
                  style={{ backgroundColor: "teal", border: "none" }}
                >
                  {" "}
                  Change in CMS
                </Button>
              )}
            </Container>
          </Container>
        )
      )}
    </BaseView>
  );
}

ServiceProvidersDetailedView.contextTypes = {
  t: PropTypes.func.isRequired,
};
