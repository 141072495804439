import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Badge, Row, Col, Image } from "react-bootstrap";

import { addDefaultImageSrc } from "../common/utilities/utilities";

export default function CommonContentListItem(props, context) {
  return (
    <Link to={`/${props.type}/${props.id}`} className="Card">
      <Row className="mt-3" style={{ height: 60 }}>
        <Col xs={3} sm={2} md={2} lg={1}>
          <Image
            src={props.image ? props.image : "/static/media/guide_news.jpg"}
            crossOrigin="anonymous"
            style={{
              width: "100%",
              height: 60,
              objectFit: "cover",
              border: "1px solid hsl(0,0%,80%)",
            }}
            onError={addDefaultImageSrc}
          />
          {(props.video || props.audio) && (
            <div className="audio-video-container">
              {props.video && (
                <i className="fas fa-video fa-xs audio-video-icon"></i>
              )}
              {props.audio && (
                <i className="fas fa-volume-down audio-video-icon"></i>
              )}
            </div>
          )}
        </Col>
        <Col xs={9} sm={10} md={10} lg={11} style={{ paddingLeft: 0 }}>
          {props.has_news_notifications === true && (
            <Badge className="newContentBadge">{context.t("new")}</Badge>
          )}
          <h3 className="ListItemName" style={{ marginBottom: 30 }}>
            {" "}
            {props.title.length > 50
              ? `${props.title.substring(0, 50)}...`
              : props.title}{" "}
          </h3>
          <p
            className="mt-3"
            style={{
              color: "grey",
              fontSize: "0.75rem",
              position: "absolute",
              bottom: "0",
              marginBottom: 0,
            }}
          >
            {context.t("Published: {date}", { date: props.created_at })}
          </p>
          <div
            style={{
              position: "absolute",
              bottom: 0,
              right: "2em",
              color: "grey",
            }}
          >
            <i className="far fa-comment-alt"></i>
            <span> {props.comments_count} </span>
          </div>
        </Col>
      </Row>
      <hr style={{ marginTop: 5 }} />
    </Link>
  );
}

CommonContentListItem.contextTypes = {
  t: PropTypes.func.isRequired,
};
