import React from "react";
import { FaRegTrashAlt } from "react-icons/fa";
import { connect } from 'react-redux';
import { Col, Image } from 'react-bootstrap';
import Rater from 'react-rater'
import 'react-rater/lib/react-rater.css'

const Subrating = (props) => {

  const onChange = (rating) => {
    props.onRate({
      rating_criteria: props.id,
      score: rating.rating,
    })
  }

  const handleRemoveRating = () => {
    props.onRate({
      rating_criteria: props.id,
      score: 0,
    })
  }

  const image = props.image? props.image : '/static/media/ImagePlaceholder.png'

  return (
    <Col xs={6} className='mt-3'>
      <Image src={image} crossOrigin='anonymous' style={{width:'40px', height:'40px', objectFit:'cover'}} />
      <div style={{ display: 'flex', alignItems: 'center' , justifyContent: 'center' }}>
        <FaRegTrashAlt style={{ marginRight: '10px', color: 'red', cursor:'pointer' }} onClick={handleRemoveRating}/>
        <Rater rating={props.score} total={5} onRate={onChange} style={{ fontSize: '1.4em' }} />
      </div>
      <p style={{ marginBottom: 0, fontWeight: 600, fontSize: '0.9em' }}> {props.name} </p>
      <p style={{ marginBottom: 0, fontSize: '0.8rem', color:'grey' }}> {props.description} </p>
    </Col>
  )
}


const mapStateToProps = state => {
  return {
    lang: state.i18nState.lang,
    contacts: state.ContactsState,
    countries: state.CountriesState,
  }
}

export default connect(mapStateToProps)(Subrating);
