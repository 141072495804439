import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import {
  Container,
  Row,
  Col,
  Form,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

import BaseView from "../BaseView";
import CountryCodesSelect from "../../components/selects/CountryCodesSelect";
import { toast } from "react-toastify";
import axios from "axios";
import { countryCodeModifier } from "../../common/utilities/utilities";
import { validateInput } from "../../common/utilities/validation";

export default function ForgotPasswordView(props, context) {
  const profile = useSelector((state) => state.ProfileState.data);

  const [payload, setPayload] = useState({
    country_code: "",
    phone_number: "",
  });
  const [isLoading, setIsLoading] = useState(false); // Loading state

  useEffect(() => {
    if (profile && Object.keys(profile).length > 0) {
      setPayload({
        ...payload,
        country_code: profile.country_code,
        phone_number: profile.phone_number,
      });
    }
  }, [profile]);

  const sendPasswordLink = async (e) => {
    e.preventDefault();
    if (payload.phone_number === "" || payload.country_code === "") {
      toast.error(context.t("Please complete the form!"));
      return;
    } else {
      let url = `${process.env.REACT_APP_API_V2_URL}/generate-reset-token/`;
      let formData = new FormData();
      formData.append(
        "country_code",
        countryCodeModifier(payload.country_code)
      );
      formData.append("phone_number", payload.phone_number);

      setIsLoading(true); // Set loading state to true

      try {
        const response = await axios.post(url, formData);
        toast.success(context.t("Password reset link sent successfully!"));
        setIsLoading(false); // Reset loading state
      } catch (error) {
        setIsLoading(false); // Reset loading state
        if (error.response) {
          const statusCode = error.response.status;
          switch (statusCode) {
            case 400:
              toast.error(
                context.t(
                  "Bad Request: Please check the information you provided!"
                )
              );
              break;
            case 404:
              toast.error(
                context.t(
                  "Not Found: The requested phone number was not found!"
                )
              );
              break;
            case 500:
              toast.error(
                context.t("Internal Server Error: Please try again later!")
              );
              break;
            default:
              toast.error(context.t("An error occurred: " + statusCode));
          }
        } else if (error.request) {
          toast.error(
            context.t("Network error: Please check your internet connection!")
          );
        } else {
          toast.error(context.t("Error: " + error.message));
        }
        console.log("Error message:", error);
      }
    }
  };

  return (
    <BaseView title={context.t("Forgot Password")} backurl="/login">
      <hr className="afterTopNavigationBar"></hr>
      <Container style={{ textAlign: "left" }}>
        <h3>{context.t("Enter your phone number to reset")}</h3>
        <Form>
          <Form.Group>
            <Form.Label style={{ fontSize: "smaller" }}>
              *{context.t("Phone number")} ({context.t("required")})
            </Form.Label>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <CountryCodesSelect
                  placeholder={context.t("Select")}
                  initialValue={payload.country_code}
                  onChange={(country_code) => {
                    if (country_code === 977) {
                      document.getElementById("reset-btn").disabled = true;
                      toast.error(
                        context.t(
                          "This feature will be available in Nepal Soon!"
                        )
                      );
                      setPayload({
                        ...payload,
                        country_code: country_code,
                      });
                      return;
                    } else {
                      document.getElementById("reset-btn").disabled = false;
                      setPayload({
                        ...payload,
                        country_code: country_code,
                      });
                    }
                  }}
                />
                <Form.Text muted>
                  {context.t("Select the country code")}
                </Form.Text>
                <Form.Control.Feedback
                  type="invalid"
                  style={{ display: !payload.country_code && "block" }}
                >
                  {context.t("Please select a country code")}
                </Form.Control.Feedback>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12">
                <OverlayTrigger
                  overlay={
                    <Tooltip id="tooltip-disabled">
                      {context.t(
                        "Please remove any leading zeros when entering your phone number. For example, if your phone number is 0827474736, enter it as 827474736."
                      )}
                    </Tooltip>
                  }
                >
                  <Form.Control
                    type="tel"
                    value={payload.phone_number}
                    className="button-modified"
                    onChange={(event) => {
                      let phone_number = event.target.value;
                      let validate_key = "phone_number";
                      let validation = validateInput(
                        validate_key,
                        phone_number,
                        payload.country_code
                      );
                      if (validation) {
                        return validation;
                      }
                      setPayload({
                        ...payload,
                        phone_number: event.target.value,
                      });
                    }}
                    placeholder={context.t("Enter your phone number")}
                    style={{ height: 30 }}
                    required
                  />
                </OverlayTrigger>
                <Form.Text muted>
                  {context.t(
                    "Enter the phone number you always use. Do not include the country code, such as (+95, +66, +977, +855, +60), here again."
                  )}
                </Form.Text>
                <Form.Control.Feedback
                  type="invalid"
                  style={{ display: !payload.phone_number && "block" }}
                >
                  {context.t("Please enter valid phone number")}
                </Form.Control.Feedback>
              </div>
            </div>
            <Row className="mt-3">
              <Col>
                <Button
                  id="reset-btn"
                  style={{ width: "100%" }}
                  onClick={sendPasswordLink}
                  variant="primary button-modified"
                  disabled={isLoading} // Disable button while loading
                >
                  {isLoading
                    ? context.t("Processing...")
                    : context.t("Send Password Reset Link")}
                </Button>
              </Col>
            </Row>
          </Form.Group>
        </Form>
      </Container>
    </BaseView>
  );
}

ForgotPasswordView.contextTypes = {
  t: PropTypes.func.isRequired,
};
