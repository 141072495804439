import React, { useEffect, useState } from "react";
import "./ReactionIcons.css";

const ReactionIcons = (props) => {
  const [reactions, setReactions] = useState(props.reactionSet);
  const makeReaction = props.makeReaction;
  const type = props.type;

  useEffect(() => {
    if (props.reactionSet) {
      setReactions(props.reactionSet);
    }
  }, [props]);

  const reactionType = reactions ? reactions.reaction_type : null;

  return (
    <div>
      {reactions && reactions[`${type}_reaction_count`] && (
        <div className="reaction-icons">
          <div>
            <i
              className={`fas fa-solid fa-thumbs-up like ${
                reactionType === "like" || reactionType === "LIKE"
                  ? "active"
                  : ""
              }`}
              onClick={(e) => makeReaction(e, "like")}
            ></i>
            &nbsp;
            <span>
              {reactions !== null
                ? reactions[`${type}_reaction_count`].like
                : 0}
            </span>
          </div>
          <div>
            <i
              className={`fas fa-solid fa-heart love ${
                reactionType === "love" || reactionType === "LOVE"
                  ? "active"
                  : ""
              }`}
              onClick={(e) => makeReaction(e, "love")}
            ></i>{" "}
            &nbsp;
            <span>
              {reactions !== null
                ? reactions[`${type}_reaction_count`].love
                : 0}
            </span>
          </div>
          <div>
            <i
              className={`fas fa-solid fa-frown sad ${
                reactionType === "sad" || reactionType === "SAD" ? "active" : ""
              }`}
              onClick={(e) => makeReaction(e, "sad")}
            ></i>
            &nbsp;
            <span>
              {reactions !== null ? reactions[`${type}_reaction_count`].sad : 0}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReactionIcons;
