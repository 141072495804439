import * as firebase from "firebase/app";
import "firebase/messaging";


let messaging

const initializedFirebaseApp = firebase.initializeApp({
  // Project Settings => Add Firebase to your web app
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
});


if (firebase.messaging.isSupported()) {
  messaging = initializedFirebaseApp.messaging();

  messaging.usePublicVapidKey(
    // Project Settings => Cloud Messaging => Web Push certificates
    process.env.REACT_APP_FIREBASE_PUSH_CERTIFICATE
  );

}


export { messaging };
