import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import { IoIosSearch } from "react-icons/io";
import Form from "react-bootstrap/Form";
import { useModal, Modal } from "react-morphing-modal";
import "react-morphing-modal/dist/ReactMorphingModal.css";
import { Container, Row, Col, Image, Spinner, Toast } from "react-bootstrap";
import axios from "axios";
import "./PostPosterPanel.css";
import { toast } from "react-toastify";
import { AiFillFileImage } from "react-icons/ai";

export default function PostPosterPanel(props, context) {
  const languageId = props.languageId;
  const [posting, setPosting] = useState(false);
  const fileInputRef = useRef(null);
  const [post, setPost] = useState({
    caption: "",
    article: "",
    file: "",
  });

  const [showToast, setShowToast] = useState(false);

  const MAX_FILE_SIZE = 1024 * 1024 * 5; // 5MB

  const [error, setError] = useState(null);

  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageChange = (e) => {
    let imgFile = e.target.files[0];
    if (
      imgFile &&
      imgFile.type.startsWith("image/") &&
      imgFile.size < MAX_FILE_SIZE
    ) {
      setSelectedImage(URL.createObjectURL(imgFile));
      setPost((prevPost) => ({
        ...prevPost,
        file: imgFile,
      }));
    } else {
      // Invalid file type
      setShowToast(true);
      setError(
        context.t("Please select image! Image size should be less than 5MB")
      );
    }
  };

  const handleRemoveImage = () => {
    setSelectedImage(null);
    fileInputRef.current.value = "";
  };

  const postListSetter = props.postListSetter;

  const onPostInputChange = (e) => {
    const { name, value } = e.target;
    setPost((prevPost) => ({
      ...prevPost,
      [name]: value,
    }));
  };

  const { close, modalProps, getTriggerProps } = useModal({
    background: "white",
  });

  const filterSorter = props.filterSorter;
  const handleFilterSorter = props.handleFilterSorter;

  const handlePostClick = (e) => {
    e.preventDefault();
    setPosting(true);
    let formData = new FormData();
    formData.append("language", languageId);
    formData.append("article", post.article);
    formData.append("file", post.file);

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/discussion-forum/posts/`,
        formData
      )
      .then((response) => {
        setPosting(false);
        close();
        postListSetter(response.data);
        toast.success(context.t("Successfully posted !"));
      })
      .catch((error) => {
        toast.error(error);
        console.error("Error:", error);
      });

    setPost({
      caption: "",
      article: "",
    });
  };

  return (
    <>
      <Container className="fixed-top filter-container">
        <Row>
          <Col xs={12} lg={12} md={12} style={{ height: 38.5 }}>
            <Form.Group style={{ display: "flex" }}>
              <span
                style={{
                  paddingTop: "4.5px",
                  height: "auto",
                  alignItems: "center",
                }}
              >
                <IoIosSearch size={20} />
              </span>
              <Form.Control
                focus="true"
                placeholder={context.t("Search...")}
                type="text"
                id="search-box"
                onChange={(e) =>
                  handleFilterSorter({
                    ...filterSorter,
                    search: e.target.value,
                  })
                }
                className="search-box"
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={12} md={12}>
            <Button
              className="filter-button button-modified"
              {...getTriggerProps()}
            >
              &nbsp;&nbsp;
              {context.t("What would you like to discuss?")}
            </Button>
          </Col>
        </Row>
      </Container>
      <Modal {...modalProps} closeButton={false} className="modal-panel">
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={3000}
          autohide
          style={{
            position: "absolute",
            backgroundColor: "lightcoral",
            top: 20,
            right: 20,
            zIndex: 1,
          }}
        >
          <Toast.Body>{error}</Toast.Body>
        </Toast>
        <Form>
          <center style={{ textAlign: "left" }}>
            <Form.Label>{context.t("Write your discussion here")}</Form.Label>
            <Form.Control
              name="article"
              onChange={onPostInputChange.bind(this)}
              as="textarea"
              rows={6}
              placeholder={context.t("Write discussion here...")}
            />

            <Form.Label>{context.t("Choose an image")}</Form.Label>
            <div className="custom-file">
              <label className="custom-file-label" htmlFor="customFile">
                {selectedImage ? (
                  <img
                    src={selectedImage}
                    alt="Selected"
                    width="24"
                    height="24"
                  />
                ) : (
                  <AiFillFileImage size={24} />
                )}
              </label>
              <input
                type="file"
                className="custom-file-input"
                id="customFile"
                ref={fileInputRef}
                onChange={handleImageChange.bind(this)}
              />
            </div>
          </center>
          <center>
            {selectedImage && (
              <Container fluid className="post-image">
                <Image
                  src={selectedImage}
                  alt={context.t("Selected Image")}
                  style={{ maxHeight: "50vh" }}
                  fluid
                />
              </Container>
            )}
            {selectedImage && (
              <Button
                className="remove-btn rounded-pill"
                variant="danger"
                onClick={handleRemoveImage}
              >
                {context.t("Remove Image")}
              </Button>
            )}
            <Button
              className="post-btn rounded-pill"
              onClick={handlePostClick.bind(this)}
            >
              {posting && (
                <Spinner
                  style={{ marginRight: "5px" }}
                  animation="border"
                  size="sm"
                  role="status"
                />
              )}
              {posting ? context.t("Posting") : context.t("Post")}
            </Button>
            <Button className="post-close-button rounded-pill" onClick={close}>
              {context.t("Close")}
            </Button>
          </center>
        </Form>
      </Modal>
    </>
  );
}

PostPosterPanel.contextTypes = {
  t: PropTypes.func.isRequired,
};
