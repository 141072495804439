import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from 'prop-types'

import Select from 'react-select';

import { getCities } from '../../common/redux/actions/CitiesActions';
import { reactSelectDropdownStyle } from '../../common/styles/dropdowns';


const CitySelect = (props, context) => {
  const dispatch = useDispatch()

  const cities = useSelector(state => state.CitiesState)

  const [cityOptions, setCityOptions] = useState([])
  const [cityFilteredOptions, setCityFilteredOptions] = useState([])
  const [selectedCity, setSelectedCity] = useState(props.initialValue)

  useEffect(() => {
    !cities.fetched && dispatch(getCities())
  }, []);

  useEffect(() => {
    const cityOptions = Object.keys(cities.items).sort(
      (a,b) => cities.items[a].name > cities.items[b].name? 1 : -1).map(key => {
        return {
          value: cities.items[key].city,
          label: cities.items[key].name,
          state: cities.items[key].state
        }
    })
    setCityOptions(cityOptions)
  }, [cities]);

  useEffect(() => {
    filterCities()
    if (cities.items[selectedCity] && props.state !== cities.items[selectedCity].state) {
      setSelectedCity(null)
      if (props.onChange) {
        props.onChange(null)
      }
    }
  }, [props.state, cityOptions])

  const filterCities = () => {
    if (props.dependant) {
      if (props.state) {
        const filteredOptions = cityOptions.filter(city => city.state === props.state)
        setCityFilteredOptions(filteredOptions)
      }
    } else {
      setCityFilteredOptions(cityOptions)
    }
  }

  const handleCityChange = (data, event) => {
    const value = data? data.value : null
    setSelectedCity(value)
    if (props.onChange) {
      props.onChange(value)
    }
  }

  return (
    <Select
      name='city'
      options={cityFilteredOptions}
      value={cityOptions.filter(o => o.value === selectedCity)[0] || null}
      placeholder={props.placeholder}
      style={{textAlign:'center'}}
      onChange={handleCityChange}
      styles={reactSelectDropdownStyle}
      isSearchable={props.isSearchable}
      isMulti={props.isMulti}
      isClearable={props.isClearable}
    />
  )
}

CitySelect.contextTypes = {
  t: PropTypes.func.isRequired
}

export default CitySelect;
