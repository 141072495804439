import React, { Component } from 'react';
import PropTypes from 'prop-types'

import Select from 'react-select';
import { Container, Form, Button } from 'react-bootstrap';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup'
import ToggleButton from 'react-bootstrap/ToggleButton'
import { connect } from 'react-redux';
import {setLanguage} from "redux-i18n";

import { getCountries } from '../../common/redux/actions/CountriesActions';
import { getNationalities } from '../../common/redux/actions/NationalitiesActions';

import { updateProfile } from '../../common/redux/actions/ProfileActions';
import { updateUserNotificationSettings } from '../../common/redux/actions/UsersActions';
import { authLogout } from '../../common/redux/actions/AuthenticationActions';

import { messaging } from "../../firebase";



class IssaraStaffProfileView extends Component {

  constructor(props) {
    super(props);

    this.state = {
      notifications: this.props.profile.data.firebase_enabled,
      loadingToken: false
    };
  }

  handleLogout = () => {
    this.props.authLogout();
    this.props.history.push('/')
    window.location.reload();
  }

  toggleNotifications = (value) => {
    console.log(value)
    this.setState({...this.state, notifications: !this.state.notifications})

    if (value === true) {
      messaging.requestPermission()
        .then(async () => {
          this.setState({...this.state, loadingToken: true})
          const token = await messaging.getToken();
          const payload = {firebase_token: token, firebase_enabled: true}
          this.props.updateNotificationSettings(this.props.profile.data.id, payload)
          this.setState({...this.state, loadingToken: false})
        })
        .catch(function(err) {
          console.log("Unable to get permission to notify.", err);
        });
    } else {
      const payload = {firebase_enabled: false}
      this.props.updateNotificationSettings(this.props.profile.data.id, payload)
    }
  }


  render() {

    return (
      <Container className='mt-5'>
        {this.state.loadingToken?
          <div style={{display:'flex', height:'calc(100% - 118px)'}}>
            <img src={'/static/media/spinner.png'} alt='' className='LoadingSpinner'/>
          </div>
          :
          <div>
            {!messaging || 'serviceWorker' in navigator && !'PushManager' in window?
              <div>
                Notification feature is supported only in:<br/>
                Chrome Desktop and Mobile (version 50+)<br/>
                Firefox Desktop and Mobile (version 44+)<br/>
                Opera on Mobile (version 37+)
              </div>
              :
              <Form.Group controlId="notifications">
                <Form.Label> {this.context.t('Enable notifications:')} </Form.Label>
                <ToggleButtonGroup
                  type="radio" name='notifications-settings' size='xs' style={{display:'block'}}
                  value={this.state.notifications}
                  onChange={value => this.toggleNotifications(value)}
                >
                  <ToggleButton variant='outline-primary' value={false}> {this.context.t('Off')} </ToggleButton>
                  <ToggleButton variant='outline-primary' value={true}> {this.context.t('On')} </ToggleButton>
                  &nbsp;&nbsp;
                  <i className="fas fa-bell notification-circle"></i>
                </ToggleButtonGroup>
              </Form.Group>
            }
            <Button
              className='mb-6'
              variant='primary'
              onClick={this.handleLogout}>
              {this.context.t('Logout')}
            </Button>
          </div>
        }
      </Container>
    )
  }
}

IssaraStaffProfileView.contextTypes = {
  t: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  return {
    countries: state.CountriesState,
    lang: state.i18nState.lang,
    languages: state.LanguagesState,
    nationalities: state.NationalitiesState,
    profile: state.ProfileState,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getCountries: () => dispatch(getCountries()),
    getNationalities: () => dispatch(getNationalities()),
    updateProfile: (type, user_id, payload) => dispatch(updateProfile(type, user_id, payload)),
    setLanguage: (language) => dispatch(setLanguage(language)),
    authLogout: () => dispatch(authLogout()),
    updateNotificationSettings: (user_id, payload) => dispatch(updateUserNotificationSettings(user_id, payload))
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(IssaraStaffProfileView);
