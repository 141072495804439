import React, { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "react-bootstrap";
import BaseView from "../BaseView";
import RecruitmentAgenciesListItem from "../../components/RecruitmentAgenciesListItem";
import useRecruitmentAgencySearch from "../../common/hooks/useRecruitmentAgencySearch";
import FilterModal from "../../components/Filter/FilterModal";
import FilterResultsNotFound from "../../components/FilterResultsNotFound";
import useWindowDimensions from "../../common/hooks/useWindowDimensions";

export default function RecruitmentAgenciesListView(props, context) {
  const [query, setQuery] = useState("");
  const [cursor, setCursor] = useState("");

  const [ras, setRas] = useState([]);

  const { height, width } = useWindowDimensions();

  const [reset, setReset] = useState(false);

  const [filterSorter, setFilterSorter] = useState({
    sort: "",
    search: "",
    country: null,
    country_want_to_work: null,
  });

  const handleFilterSorter = (updated) => {
    setFilterSorter({
      ...filterSorter,
      ...updated,
    });
  };

  const filterSoterReseter = () => {
    setFilterSorter({
      search: "",
      sort: "-popularity",
      country: null,
      country_want_to_work: null,
    });
  };

  const { next, recruiters, hasMore, loading, error } =
    useRecruitmentAgencySearch(query, cursor);

  const observer = useRef();

  const lastRecruiterElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore && next.current) {
          setCursor(next.current);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  useEffect(() => {
    const savedFilterSorter = localStorage.getItem(
      "SAVED_FILTER_SORTER_RECRUITER"
    )
      ? localStorage.getItem("SAVED_FILTER_SORTER_RECRUITER")
      : false;
    if (savedFilterSorter) {
      let decodedFilterSorter = JSON.parse(savedFilterSorter);
      setFilterSorter({ ...filterSorter, ...decodedFilterSorter, search: "" });
    }
  }, []);

  useEffect(() => {
    if (
      (filterSorter.country_want_to_work !== null || filterSorter.country) !==
        null ||
      filterSorter.sort !== ""
    ) {
      localStorage.setItem(
        "SAVED_FILTER_SORTER_RECRUITER",
        JSON.stringify(filterSorter)
      );
    }

    setQuery({
      search: filterSorter.search,
      filter: filterSorter.country,
      country_want_to_work: filterSorter.country_want_to_work,
      sort: filterSorter.sort,
    });

    if (
      filterSorter.country_want_to_work === null &&
      filterSorter.country === null &&
      filterSorter.sort === "-popularity"
    ) {
      setReset(false);
    } else {
      setReset(true);
    }
  }, [filterSorter]);

  useEffect(() => {
    const uniqueRAs = Array.from(new Set(recruiters.map((r) => r.id))).map(
      (id) => {
        return recruiters.find((r) => r.id === id);
      }
    );
    setRas(uniqueRAs);
  }, [recruiters]);

  const handleMarginTop = (width) => {
    if (width > 991) {
      return "100px";
    } else {
      if (reset) {
        return "140px";
      } else {
        return "104px";
      }
    }
  };

  return (
    <BaseView title={context.t("Recruitment Agencies")} backurl="/">
      <hr className="afterTopNavigationBarTwo"></hr>
      {
        <>
          <Container>
            <FilterModal
              type="recruiter"
              handleFilterSorter={handleFilterSorter}
              filterSorter={filterSorter}
              resethandler={filterSoterReseter}
            />
          </Container>
          <Container style={{ marginTop: handleMarginTop(width) }}>
            <Row className="mt-12">
              {ras.map((item, index) => {
                if (ras.length === index + 1) {
                  return (
                    <Col
                      xs={6}
                      sm={4}
                      md={3}
                      lg={2}
                      key={index}
                      ref={lastRecruiterElementRef}
                    >
                      <RecruitmentAgenciesListItem
                        key={index + item.id}
                        id={item.id}
                        agency={item}
                      />
                    </Col>
                  );
                } else {
                  return (
                    <Col xs={6} sm={4} md={3} lg={2} key={index}>
                      <RecruitmentAgenciesListItem
                        key={index + item.id}
                        id={item.id}
                        agency={item}
                      />
                    </Col>
                  );
                }
              })}
            </Row>
            <div className="mt-4">{loading ? context.t("Loading..") : ""}</div>
          </Container>
          {ras.length == 0 && !loading && (
            <div className="mt-4">
              <FilterResultsNotFound
                text={context.t(
                  "Sorry, no recruitment agencies matched filter parameters, please try again."
                )}
              />
            </div>
          )}
        </>
      }
    </BaseView>
  );
}

RecruitmentAgenciesListView.contextTypes = {
  t: PropTypes.func.isRequired,
};
