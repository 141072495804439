// import built-in modules
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// icon
import { BsClock } from "react-icons/bs";

// Imoprt third-party modules
import moment from "moment";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  Container,
  Row,
  Col,
  Button,
  ButtonGroup,
  Form,
  Popover,
  OverlayTrigger,
  Image,
  Carousel,
  Alert,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";

// Import goldendreams modules
import {
  getMyRosterApplications,
  postMyRosterApplication,
} from "../../common/redux/actions/SubmitResumeActions";
import { getLanguages } from "../../common/redux/actions/LanguagesActions";

import {
  withdrawRosterApplication,
  extendRosterApplication,
} from "../../common/redux/actions/WithdrawExtendActions";

import Address from "../../components/Address";
import CompanyContacts from "../../components/CompanyContacts";
import BaseView from "../BaseView";
// importing rater
import Rater from "react-rater";
import "react-rater/lib/react-rater.css";
import Rating from "../../components/Rating";
import DetailedRating from "../../components/DetailedRatings";
// impor toast message
import { toast } from "react-toastify";
import "./RecruiterRosterDetailedView.css";
import { useQuery } from "react-query";
import RosterApplicationModal from "../../components/Modal/RosterApplicationModal";

export default function RecruiterRosterDetailedView(props, context) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [withdrawing, setWithdrawing] = useState(false);
  const [extending, setExtending] = useState(false);
  const [withdrew, setWithdrew] = useState(false);
  const [application, setApplication] = useState(null);
  const [userRating, setUserRating] = useState(null);
  const [showRateForm, setShowRateForm] = useState(false);
  const profile = useSelector((state) => state.ProfileState.data);
  const rosterApplications = useSelector(
    (state) => state.SubmitResumeState.rosterApplications
  );
  const ra = useSelector((state) => state.RecruitmentAgenciesState.detail);
  const { recruiter_id } = useParams();
  const [businessImages, setBusinessImages] = useState([]);
  const [raName, setRaName] = useState("");
  const applicationStatus = [
    "APPLICANT_NA",
    "VIEWED",
    "APPLIED",
    "SHORTLISTED",
    "DOWNLOADED",
    "MOVED_GENERAL_WAITLIST",
  ];

  // language filter stuffs
  const language = useSelector((state) => state.i18nState.lang);
  const languages = useSelector((state) => state.LanguagesState);
  const [languageId, setLanguageId] = useState();
  const [description, setDescrption] = useState("");

  const [submittingResume, setSubmittingResume] = useState(false);
  const [cover, setCover] = useState(null);
  const [currentResume, setCurrentResume] = useState({});
  const [payload, setPayload] = useState({
    allowed_to_view_documents: false,
    recruitment_agency: null,
    jobseeker: null,
    resume: null,
    remark: "",
  });

  useEffect(() => {
    !rosterApplications.fetching &&
      !languages.fetched &&
      languages.loading &&
      dispatch(getLanguages());
  }, []);

  useEffect(() => {
    if (profile?.id) {
      dispatch(getMyRosterApplications(profile.id));
    }
  }, [profile]);

  async function fetchRecruitmentAgency(language, recruiter_id) {
    const response = await axios.get(
      `${process.env.REACT_APP_API_V2_URL}/recruitment-agencies/${recruiter_id}?lang=${language}`
    );
    return response.data;
  }

  // React Query for fetching recruiter detail
  const { data: recruitmentAgency, isLoading: isLoadingRecruitmentAgency } =
    useQuery(["agencyRosterDetail", recruiter_id], () =>
      fetchRecruitmentAgency(language, recruiter_id)
    );

  useEffect(() => {
    if (Object.keys(languages.items).length != 0 && language) {
      let langObj = languages.itemsArray.find((lang) => lang.code == language);
      setLanguageId(langObj.id);
    }
  }, [languages, language]);

  useEffect(() => {
    if (
      languageId &&
      recruitmentAgency &&
      Object.keys(recruitmentAgency).length != 0 &&
      recruitmentAgency.translations &&
      recruitmentAgency.translations.length > 0
    ) {
      let translatedObj = recruitmentAgency.translations.find(
        (tran) => tran.language == languageId
      );
      setDescrption(translatedObj ? translatedObj.description : "");
    }
  }, [languageId, recruitmentAgency]);

  useEffect(() => {
    if (
      rosterApplications.fetched &&
      rosterApplications.itemsArray.length > 0 &&
      recruiter_id
    ) {
      rosterApplications.itemsArray.filter((application) => {
        if (application.recruitment_agency == recruiter_id) {
          setApplication(application);
        }
      });
    }

    if (
      recruitmentAgency &&
      Array.isArray(recruitmentAgency.bussiness_images) &&
      recruitmentAgency.bussiness_images.length > 0
    ) {
      let cover = recruitmentAgency.bussiness_images.filter(
        (image) => image.cover
      );
      setCover(cover[0]);
    }

    if (
      recruitmentAgency &&
      Array.isArray(recruitmentAgency.bussiness_images) &&
      recruitmentAgency.bussiness_images.length > 0
    ) {
      let business_images = recruitmentAgency.bussiness_images.filter(
        (image) => !image.cover
      );
      setBusinessImages(business_images);
    }

    if (
      recruitmentAgency &&
      Array.isArray(recruitmentAgency.translations) &&
      recruitmentAgency.translations.length > 0
    ) {
      setRaName(recruitmentAgency.translations[0].name);
    }
  }, [recruitmentAgency, rosterApplications]);

  useEffect(() => {
    if (recruitmentAgency && profile.id) {
      axios
        .get(
          `${process.env.REACT_APP_API_V2_URL}/jobseekers/${profile.id}/resume`
        )
        .then((res) => {
          if (res.data.resume) {
            setCurrentResume(res.data.resume);
            setPayload({
              ...payload,
              recruitment_agency: recruiter_id,
              jobseeker: profile.id,
              resume: res.data.resume.id,
            });
          }
        })
        .catch((err) => {
          console.error("Failed to fetch resume", err);
        });
    }
  }, [recruitmentAgency, profile]);

  const submitResume = (e) => {
    e.preventDefault();
    setSubmittingResume(true);
    dispatch(postMyRosterApplication(payload))
      .then((res) => {
        dispatch(getMyRosterApplications(profile.id));
        setWithdrew(false);
        toast.success(context.t("Successfully sent the resume"));
      })
      .catch((err) => {
        toast.error(context.t("Couldn't sent the resume due to server error"));
      })
      .finally(() => setSubmittingResume(false));
  };

  const extendSpecificRosterApplication = (applicationId) => {
    setExtending(true);
    if (applicationId) {
      if (dispatch(extendRosterApplication(applicationId))) {
        setExtending(false);
        dispatch(getMyRosterApplications(profile.id));
        toast.success(context.t("Successfully extended the application !"));
      }
    }
  };

  const checkStatus = () => {
    if (application && application.status) {
      if (application.status == "APPLIED") {
        return (
          <div style={{ marginBottom: "10px" }}>
            <Alert className="hover-effect" variant="info">
              {context.t("You already sent your cv to this Recruitment Agency")}
            </Alert>
            <OverlayTrigger
              trigger="click"
              placement="top"
              overlay={withdrawConfirmation}
              rootClose={true}
            >
              <Button className="button-modified roster-extend-withdraw-buttons gd-red">
                {context.t("Withdraw application")}
              </Button>
            </OverlayTrigger>
            <Button
              className="button-modified roster-extend-withdraw-buttons gd-green"
              onClick={extendSpecificRosterApplication.bind(
                this,
                application.id
              )}
            >
              {context.t("Extend application")}
            </Button>
          </div>
        );
      } else if (application.status == "VIEWED") {
        return (
          <Alert className="hover-effect" variant="success">
            {context.t("Recruitment Agency viewed your application")}
          </Alert>
        );
      } else if (application.status == "DOWNLOAED") {
        return (
          <Alert className="hover-effect" variant="success">
            {context.t("Recruitment Agency Downloaded your resume")}
          </Alert>
        );
      } else if (application.status == "MOVED_GENERAL_WAITLIST") {
        return (
          <Alert className="hover-effect" variant="success">
            {context.t(
              "Recruitment Agency Moved your application to General Waitlist"
            )}
          </Alert>
        );
      } else if (application.status == "SHORTLISTED") {
        return (
          <Alert className="hover-effect" variant="success">
            {context.t("Recruitment Agency Shortlisted your application")}
          </Alert>
        );
      } else if (application.status == "APPLICANT_NA") {
        return (
          <Alert className="hover-effect" variant="danger">
            {context.t(
              "You cannot send your resume to this recruiter as you are not residing in same country with this recruiter."
            )}
          </Alert>
        );
      }
    }
  };

  const withdrawApplicationNow = (e, id) => {
    e.preventDefault();
    setWithdrawing(true);
    setPayload({
      ...payload,
      allowed_to_view_documents: false,
    });
    setApplication({
      ...application,
      status: application && application.status,
    });
    dispatch(withdrawRosterApplication(id))
      .then((res) => {
        toast.success(context.t("Successfully withdrew the application !", 5));
        setWithdrawing(false);
        setWithdrew(true);
        dispatch(getMyRosterApplications(profile.id));
      })
      .catch((err) => {
        toast.error(
          context.t("Couldn't withdraw the application due to server error")
        );
      })
      .finally(() => setSubmittingResume(false));
  };

  const withdrawConfirmation = (
    <Popover>
      <Popover.Title
        as="h6"
        style={{ color: "white", backgroundColor: "#198474" }}
      >
        <strong>{context.t("Confirmation !")}</strong>
      </Popover.Title>
      <Popover.Content>
        <p>
          {context.t(
            "Are you sure want to withdraw this application? Your application will no longer see in {recruiter_name}'s list",
            { recruiter_name: raName }
          )}
        </p>
        <ButtonGroup className="mt-3">
          <Button
            variant="outline-secondary"
            className="button-modified roster-extend-withdraw-buttons"
            size="sm"
            disabled={submittingResume}
            onClick={() => document.body.click()}
          >
            {context.t("Cancel")}
          </Button>

          {recruitmentAgency && application && (
            <Button
              className="button-modified roster-extend-withdraw-buttons gd-red"
              disabled={withdrawing}
              onClick={(e) => withdrawApplicationNow(e, application.id)}
              size="sm"
            >
              <strong>
                {withdrawing
                  ? context.t("Withdrawing Application")
                  : context.t("Yes, withdraw")}
              </strong>
            </Button>
          )}
        </ButtonGroup>
      </Popover.Content>
    </Popover>
  );

  const checkApplicableCountry = (recruiter, profile) => {
    if (
      recruiter.addresses &&
      recruiter.addresses[0] &&
      currentResume &&
      currentResume.country
    ) {
      if (recruiter.addresses[0].country_id && currentResume.country) {
        if (currentResume.country == recruiter.addresses[0].country_id) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  };

  useEffect(() => {
    if (withdrew) {
      dispatch(getMyRosterApplications(profile.id));
    }
  }, [withdrew, dispatch, profile.id]);

  return (
    <BaseView title={context.t("Recruiter Detail")} backurl="/recruiter-roster">
      <hr className="afterTopNavigationBar"></hr>
      {isLoadingRecruitmentAgency || !recruitmentAgency ? (
        <div style={{ display: "flex", height: "calc(100% - 118px)" }}>
          <Image
            src={"/static/media/spinner.png"}
            alt=""
            className="LoadingSpinner"
          />
        </div>
      ) : (
        <Container>
          <Card style={{ textAlign: "left" }}>
            <Card.Header>
              {application && !withdrew && application.expired_at && (
                <Card.Title>
                  <center>
                    <p
                      style={{
                        color: "brown",
                        display: "inline-block",
                        marginBottom: "0",
                        paddingBottom: "0",
                      }}
                    >
                      {context.t("Your application will expire at")}&nbsp;
                    </p>
                    : <BsClock />
                    &nbsp;
                    {application && moment(application.expired_at).format("LL")}
                  </center>
                </Card.Title>
              )}
              <div className="profile-cover-container mb-2">
                {cover?.image && (
                  <Image
                    className="cover-image"
                    variant="top"
                    src={cover.image}
                  />
                )}
                {!cover && <h3>{raName}</h3>}
                <div className="profile-container">
                  <Image
                    className="profile-img"
                    src={
                      recruitmentAgency.logo
                        ? recruitmentAgency.logo
                        : "/static/media/ImagePlaceholder.png"
                    }
                  />
                </div>
              </div>
            </Card.Header>
            <Card.Body className="mt-5">
              <h3>{raName}</h3>
              {recruitmentAgency.status === "Operational" && (
                <p className="operational">
                  <i className="fa-regular fa-circle-check"></i>&nbsp;
                  {context.t("Operational")}
                </p>
              )}
              {recruitmentAgency.status === "Temporarily Suspended" && (
                <div className="temporarily-suspended">
                  <i className="far fa-pause-circle"></i>
                  {context.t("Temporarily Suspended")}
                  {recruitmentAgency.status_from_date && (
                    <p
                      style={{
                        color: "black",
                        fontWeight: 600,
                        marginBottom: 0,
                      }}
                    >
                      {context.t("from: {date}", {
                        date: moment(recruitmentAgency.status_from_date).format(
                          "LL"
                        ),
                      })}
                    </p>
                  )}
                  {recruitmentAgency.status_to_date && (
                    <p style={{ color: "black", fontWeight: 600 }}>
                      {context.t("to: {date}", {
                        date: moment(recruitmentAgency.status_to_date).format(
                          "LL"
                        ),
                      })}
                    </p>
                  )}
                </div>
              )}
              {recruitmentAgency.status === "Permanently Blacklisted" && (
                <div className="permanently-blacklisted">
                  <i className="fa-sharp fa-regular fa-circle-xmark"></i>
                  {context.t("Permanently Blacklisted")}
                  {recruitmentAgency.status_from_date && (
                    <p style={{ color: "black", fontWeight: 600 }}>
                      {context.t("from: {date}", {
                        date: moment(recruitmentAgency.status_from_date).format(
                          "LL"
                        ),
                      })}
                    </p>
                  )}
                </div>
              )}
              {recruitmentAgency.status === "License is not extended" && (
                <div className="license-is-not-extended">
                  <i className="fas fa-id-badge"></i>
                  {context.t("License is not extended")}
                  {recruitmentAgency.status_from_date && (
                    <p style={{ color: "black", fontWeight: 600 }}>
                      {context.t("from: {date}", {
                        date: moment(recruitmentAgency.status_from_date).format(
                          "LL"
                        ),
                      })}
                    </p>
                  )}
                </div>
              )}
              {description && (
                <p>
                  <strong>{context.t("Description")}:</strong> {description}
                </p>
              )}
              {recruitmentAgency.date_of_registration && (
                <p>
                  <span className="bold">{context.t("Registered:")}</span>
                  {moment(recruitmentAgency.date_of_registration).format("LL")}
                </p>
              )}
              {recruitmentAgency.license_number && (
                <p>
                  <span className="bold">{context.t("License number:")}</span>
                  {recruitmentAgency.license_number}
                </p>
              )}
              {recruitmentAgency.license_valid_date && (
                <p>
                  <span className="bold">{context.t("Valid to:")}</span>
                  {moment(recruitmentAgency.license_valid_date).format("LL")}
                </p>
              )}
              <div className="mt-3">
                <h5>{context.t("Contact Information")} </h5>
                <CompanyContacts
                  website={recruitmentAgency.website}
                  email={recruitmentAgency.email}
                  phone_numbers={recruitmentAgency.phone_numbers}
                  facebook={recruitmentAgency.facebook}
                  viber={recruitmentAgency.viber}
                  line={recruitmentAgency.line}
                />
              </div>
              {recruitmentAgency.rating_score && (
                <>
                  <hr />
                  <Rater
                    total={5}
                    rating={recruitmentAgency.rating_score}
                    interactive={false}
                  />
                  <span>
                    {recruitmentAgency.rating_score &&
                      recruitmentAgency.rating_score.toFixed(1)}
                  </span>
                  <span
                    style={{
                      position: "relative",
                      bottom: "7px",
                      fontSize: "0.7em",
                    }}
                  >
                    ({recruitmentAgency.rating_count})
                  </span>
                </>
              )}
              {recruitmentAgency.rating_count && (
                <p className="rated-by-text" style={{ textAlign: "center" }}>
                  {context.t("Rated by ({number}) viewers", {
                    number: recruitmentAgency.rating_count,
                  })}
                  &nbsp;&nbsp;<i className="fa-regular fa-circle-check"></i>
                </p>
              )}
              {showRateForm ? (
                <Rating
                  group="Recruitment Agency"
                  object_id={recruiter_id}
                  content_type="recruitment agency profile"
                  userRating={recruitmentAgency.user_ratings}
                  onSubmit={() => setShowRateForm(false)}
                />
              ) : (
                <DetailedRating
                  overall_score={recruitmentAgency.rating_score}
                  scores_for_each_criteria={
                    recruitmentAgency.scores_for_each_criteria
                  }
                />
              )}
              {Array.isArray(recruitmentAgency.addresses) &&
              recruitmentAgency.addresses.length > 0 ? (
                <div>
                  <hr />
                  <h3>{context.t("Address")}</h3>
                  {recruitmentAgency.addresses.map((address, i) => (
                    <Address
                      key={i}
                      name_en={recruitmentAgency.name_en} // used in a google maps search
                      street={address.street}
                      city={address.city_id}
                      state={address.state_id}
                      country={address.country_id}
                      zip={address.zip}
                      addressContacts={address.contacts}
                    />
                  ))}
                </div>
              ) : (
                <p className="mt-3">
                  {context.t("No Focal Person contact provided")}
                </p>
              )}
            </Card.Body>
            {businessImages.length > 0 && (
              <Card.Footer>
                <>
                  <Carousel>
                    {businessImages.map((recruiterImage) => {
                      return (
                        <Carousel.Item key={recruiterImage.id}>
                          <Image
                            className="d-block w-100"
                            src={recruiterImage.image}
                            alt={recruitmentAgency.name}
                          />
                          <Carousel.Caption>
                            <strong>
                              <p>{raName}</p>
                            </strong>
                          </Carousel.Caption>
                        </Carousel.Item>
                      );
                    })}
                  </Carousel>
                </>
              </Card.Footer>
            )}
            <Container style={{ textAlign: "center" }}>
              <Row>
                <Col>
                  {/* check resume already existed or not */}
                  {currentResume ? (
                    checkApplicableCountry(recruitmentAgency, profile) ? (
                      !withdrew &&
                      application &&
                      applicationStatus.includes(application.status) ? (
                        checkStatus()
                      ) : (
                        <RosterApplicationModal
                          type="recruiter"
                          payload={payload}
                          setPayload={setPayload}
                          submitResume={submitResume}
                          submittingResume={submittingResume}
                        />
                      )
                    ) : (
                      <Alert className="hover-effect" variant="danger">
                        {context.t(
                          "You cannot send your resume to this recruiter as you are not residing in same country with this recruiter."
                        )}
                      </Alert>
                    )
                  ) : (
                    <Alert
                      className="hover-effect"
                      variant="warning"
                      onClick={() => history.push("/resume")}
                      style={{ cursor: "pointer" }}
                    >
                      {context.t("Click here to create a resume")}
                    </Alert>
                  )}
                </Col>
              </Row>
            </Container>
          </Card>
        </Container>
      )}
    </BaseView>
  );
}

RecruiterRosterDetailedView.contextTypes = {
  t: PropTypes.func.isRequired,
};
