// styles.js
const styles = {
  replyIcon: {
    fontSize: "40px",
    marginBottom: "10px"
  },
  rowdesign: {
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
  },
  senderavatar: {
    width: "45px",
    height: "45px",
    borderRadius: "100%",
    border: "1px solid #e9ecef",
  },
  commentreplay: {
    display: "flex",
    justifyContent: "space-between"
  },
  iconstyle: {
    color: "#198474",
    marginLeft: 4,
  },
  sendername: {
    color: "grey",
    fontSize: "0.7em",
    marginTop: "-0.5rem",
    marginLeft: 4,
    fontWeight: 500,
  },
  createddate: {
    color: "grey",
    fontSize: "0.8em",
    marginTop: "-0.5rem",
  },
  commenttext: {
    marginTop: "-0.5rem",
    marginBottom: 0,
    wordBreak: "break-word",
  },
  commentreplayicons: {
    backgroundColor: "#fff",
    border: "1px solid gray",
    borderRadius: "5px",
    padding: "5px",
    margin: "-40px 0 5px 0",
  }
};

export default styles;
