import React from "react";
import { Row, Col } from 'react-bootstrap';


const Divider = (props) => {

  return (
    <Row className={props.className} style={props.style}>
      <Col><hr/></Col>
      <Col xs={'auto'}>{props.text}</Col>
      <Col><hr/></Col>
    </Row>
  )
}


export default Divider;
