import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Form, Button } from "react-bootstrap";
import { authLogin } from "../common/redux/actions/AuthenticationActions";

class EmailUsernameLoginForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      payload: {
        email_username: "",
        password: "",
      },
      errors: {},
      showPassword: false, // State to manage password visibility
    };
  }

  /* BASE HANDLERS */

  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      payload: {
        ...this.state.payload,
        [name]: value,
      },
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.authLogin(this.state.payload);
  };

  toggleShowPassword = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };

  render() {
    const validated =
      this.state.payload.email_username &&
      this.state.payload.password &&
      !this.state.errors.password;

    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.Group controlId="email_username">
          <Form.Control
            name="email_username"
            style={{ borderRadius: "60px" }}
            className={this.state.errors.email_username && "warning"}
            placeholder={this.context.t("Username or email")}
            onChange={this.handleChange}
          />
        </Form.Group>
        <Form.Group controlId="password">
          <Form.Control
            name="password"
            type={this.state.showPassword ? "text" : "password"}
            style={{ borderRadius: "60px" }}
            className={this.state.errors.password && "warning"}
            placeholder={this.context.t("Password")}
            onChange={this.handleChange}
          />
        </Form.Group>
        <Form.Group controlId="showPassword" className="text-left">
          <Form.Check
            type="checkbox"
            label={this.context.t("Show Password")}
            checked={this.state.showPassword}
            onChange={this.toggleShowPassword}
          />
        </Form.Group>
        <Button type="submit" className="button-modified" disabled={!validated}>
          {this.context.t("Login")}
        </Button>
      </Form>
    );
  }
}

EmailUsernameLoginForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    lang: state.i18nState.lang,
    error: state.AuthenticationState.errors.login,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    authLogin: (payload) => dispatch(authLogin(payload)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailUsernameLoginForm);
