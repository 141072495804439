import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Image, Card, Container, Form } from "react-bootstrap";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import "react-rater/lib/react-rater.css";
import { AiOutlineComment } from "react-icons/ai";
import CommentModal from "../../components/CommentModal";
import ReactionIcons from "../../components/ReactionIcons";
import axios from "axios";
import moment from "moment";
import PostEditorModal from "./PostEditorModal";
import DeleteConfirmModal from "./DeleteConfirmModal";
import {
  addDefaultImageSrc,
  deletePermissionLogic,
} from "../../common/utilities/utilities";
import "./PostItem.css";

const PostItem = (props, context) => {
  const [comments, setComments] = useState([]);
  const post = props.item;
  const lang = props.lang;
  const profile = props.profile;
  const [commentModalShow, setCommentModalShow] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const postListRemover = props.postListRemover;
  const languageId = props.languageId;
  const postListSetter = props.postListSetter;
  const [data, setData] = useState({
    fetched: false,
    fetching: false,
  });
  const [reaction, setReaction] = useState({});

  const onLikeClick = (e, type) => {
    e.preventDefault();
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/discussion-forum/posts/${post.id}/react/?lang=${lang}`,
        {
          reaction_type: type.toUpperCase(),
        }
      )
      .then((response) => {
        updatePostComments(post.id);
      })
      .catch((error) => {
        console.error("Error:", error.message);
        // Handle any errors that occurred during the request
      });
  };

  const updatePostComments = (post_id) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/discussion-forum/posts/${post_id}?lang=${lang}`
      )
      .then(function (response) {
        postListSetter(response.data);
      });
  };
  const onCommentClick = (post_id, e) => {
    e.preventDefault();
    setCommentModalShow(true);
    setData({
      ...data,
      fetching: true,
      fetched: false,
    });
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/discussion-forum/posts/${post_id}/list-comments/`
      )
      .then((res) => {
        setComments(res.data.comments);
        setData({
          ...data,
          fetching: false,
          fetched: true,
        });
      })
      .catch((err) => console.log(err));
  };

  const closeButtonClick = (e) => {
    e.preventDefault();
    setCommentModalShow(false);
  };

  const toggleExpand = (e) => {
    e.preventDefault();
    setExpanded(!expanded);
  };

  const renderText = (text, context) => {
    // if the text is over 5 show See more button
    if (text && text != null && text != "") {
      if (text.length <= 150) {
        return text;
      } else if (expanded) {
        return text;
      } else {
        // const truncatedText = text.split("\n").slice(0, 5).join("\n");
        const truncatedText = text.substring(0, 150);
        return (
          <span>
            {truncatedText}
            <span style={{ color: "grey" }} onClick={toggleExpand.bind(this)}>
              {context.t("...See more")}
            </span>
          </span>
        );
      }
    } else {
      return "";
    }
  };

  return (
    <Card className="mt-3" style={{ textAlign: "left" }}>
      <Card.Header style={{ height: "70px" }}>
        <Card.Title style={{ fontSize: "1rem" }}>
          <Image
            onError={(e) => (e.target.src = "/static/media/l60Hf.png")}
            className="profile-circle-image"
            src={post.avatar ? post.avatar : "/static/media/l60Hf.png"}
          />
          &nbsp;{post.username}
          <span style={{ float: "right", paddingBottom: "30px" }}>
            {deletePermissionLogic(post, profile) && (
              <DropdownButton
                id="dropdown-option"
                variant={"outline-dark"}
                title="..."
              >
                <Dropdown.Item>
                  <PostEditorModal
                    type="list"
                    postListSetter={postListSetter}
                    post_id={post.id}
                    languageId={languageId}
                  />
                </Dropdown.Item>
                <Dropdown.Item>
                  <DeleteConfirmModal
                    type="list"
                    postListRemover={postListRemover}
                    post_id={post.id}
                  />
                </Dropdown.Item>
              </DropdownButton>
            )}
          </span>
          <Card.Subtitle
            style={{
              color: "grey",
              fontSize: "0.85rem",
              paddingLeft: "35px",
              paddingTop: "5px",
              width: "fit-content",
            }}
          >
            {moment(post.posted).fromNow()}
          </Card.Subtitle>
        </Card.Title>
      </Card.Header>
      <Link to={`/groups/${props.id}/${languageId}`} style={{ color: "black" }}>
        <Card.Body>
          <Card.Text>{renderText(post.article, context)}</Card.Text>
          {post.file && (
            <Container fluid className="post-image">
              <Image
                onError={addDefaultImageSrc}
                style={{ maxHeight: "400px" }}
                alt={post.caption}
                src={post.file}
                fluid
              />
            </Container>
          )}
        </Card.Body>
      </Link>
      <Card.Footer>
        <ReactionIcons
          type="comment"
          makeReaction={onLikeClick}
          reactionSet={post.reaction_counts}
        />
        <Link
          id="comment_icon"
          to={`/groups/${props.id}/${languageId}`}
          style={{ color: "black" }}
        >
          <i>
            <AiOutlineComment size={17} />
            &nbsp;{post.comments_count}
          </i>
        </Link>
      </Card.Footer>
    </Card>
  );
};

PostItem.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    lang: state.i18nState.lang,
  };
};

export default connect(mapStateToProps)(PostItem);
