import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import axios from 'axios';
import { anonymizeComment } from "../utilities/utilities";

export default function useRecruiterComment( cursor, id, respondedComment, commentAction) {
    const language = useSelector(state => state.i18nState.lang)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [raComments, setRaComments] = useState([])
    const [hasMore, setHasMore] = useState(false)
    const next = useRef(`${process.env.REACT_APP_API_V2_URL}/ra-comments/${id}?lang=${language}`)

    useEffect(() => {
        setRaComments([])
        next.current = `${process.env.REACT_APP_API_V2_URL}/ra-comments/${id}?lang=${language}`
    }, [])

    useEffect(() => {
        if(respondedComment){
            anonymizeComment(respondedComment);
            updateOrAddComment(respondedComment);
        }
    },[respondedComment]);

    useEffect(() => {
        if(commentAction){
            // currently we don't have specific instruction for hiding that's why 
            if(commentAction.type == 'delete' || commentAction.type == 'hide'){
                const updatedComments = raComments.filter(comment => comment.id !== commentAction.comment.id);
                setRaComments([...updatedComments]);
            }
        }
    },[commentAction]);

    const updateOrAddComment = (newComment) => {
        if(!newComment.base_parent){
            setRaComments(prevcomments => [...prevcomments, ...[newComment]])
        }else{
            let updatedComments = raComments.map(comment => {
                if(comment.id === newComment.base_parent){
                    return {...comment, children: [...comment.children, newComment]};
                }else{
                    return comment;
                }
            });
            setRaComments([...updatedComments]);
        }
    };

    useEffect(() => {
        let cancel;
        setError(false)
        setLoading(true)
        axios({
            method: 'GET',
            url: next.current,
            cancelToken: new axios.CancelToken(c => cancel = c)
        }).then(res => {
            setError(false)
            setLoading(false)
            let comments = []
            Object.keys(res.data.results).map(key => {
                comments.push(res.data.results[key])
            })

            setRaComments(prevcomments => [...prevcomments, ...comments])

            if (res.data.next) {
                setHasMore(true)
                next.current = res.data.next
            } else {
                setHasMore(false)
            }

        }).catch(e => {
            if (axios.isCancel(e)) return
            setError(true)
        })

        return () => cancel()
    }, [ cursor, id])

    return { next, loading, error, raComments, hasMore }
}