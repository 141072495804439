import React, { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Button } from "react-bootstrap";
import BaseView from "../BaseView";
import axios from "axios";
import useGroupPostsSearch from "../../common/hooks/useGroupPostsSearch";
import useWindowDimensions from "../../common/hooks/useWindowDimensions";
import PostPosterPanel from "./PostPosterPanel";
import { getLanguages } from "../../common/redux/actions/LanguagesActions";
import PostItem from "./PostItem";
import { useHistory } from "react-router-dom";
import { AiOutlineArrowRight } from "react-icons/ai";
import FilterResultsNotFound from "../../components/FilterResultsNotFound";
import "./PostListView.css";

export default function PostListView(props, context) {
  const dispatch = useDispatch();
  const history = useHistory();
  const languages = useSelector((state) => state.LanguagesState);
  const lang = useSelector((state) => state.i18nState.lang);
  const profile = useSelector((state) => state.ProfileState.data);
  const [languageId, setLangaugeId] = useState(1);

  useEffect(() => {
    !languages.fetched && dispatch(getLanguages());
  }, []);

  useEffect(() => {
    if (languages.itemsArray && languages.itemsArray.length > 0) {
      const filteredId = languages.itemsArray
        .filter((obj) => obj.code.startsWith(lang))
        .map((obj) => obj.id)
        .pop();
      setLangaugeId(filteredId);
    }
  }, [languages]);

  // Query and Cursor
  const [query, setQuery] = useState("");
  const [cursor, setCursor] = useState("");
  //resolve duplicated ra from searching query
  const [posts, setPosts] = useState([]);

  // get window width height
  const { height, width } = useWindowDimensions();
  // check reset state
  const [reset, setReset] = useState(false);

  // filter sorter state & handler for Filter Modal
  const [filterSorter, setFilterSorter] = useState({
    country: "",
    sort: "",
    search: "",
  });

  // call back function for child component Filter Modal
  const handleFilterSorter = (updated) => {
    setFilterSorter({
      ...filterSorter,
      ...updated,
    });
  };

  const filterSoterReseter = () => {
    setFilterSorter({
      country: "",
      sort: "-popularity",
      search: "",
    });
  };

  const { next, groupPosts, hasMore, loading, error } = useGroupPostsSearch(
    query,
    cursor
  );

  const observer = useRef();

  const lastPostRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore && next.current) {
          setCursor(next.current);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  useEffect(() => {
    // check saved settings
    const savedFilterSorter = localStorage.getItem(
      "SAVED_FILTER_SORTER_RECRUITER"
    )
      ? localStorage.getItem("SAVED_FILTER_SORTER_RECRUITER")
      : false;
    if (savedFilterSorter) {
      let decodedFilterSorter = JSON.parse(savedFilterSorter);
      setFilterSorter({ ...filterSorter, ...decodedFilterSorter, search: "" });
    }
  }, []);

  // initial method for all filter sorter function
  useEffect(() => {
    if (filterSorter.country != "" || filterSorter.sort != "") {
      localStorage.setItem(
        "SAVED_FILTER_SORTER_RECRUITER",
        JSON.stringify(filterSorter)
      );
    }

    setQuery({
      search: filterSorter.search,
      filter: filterSorter.country,
      sort: filterSorter.sort,
    });

    // check reset state
    if (filterSorter.country == "" && filterSorter.sort == "-popularity") {
      setReset(false);
    } else {
      setReset(true);
    }
  }, [filterSorter]);

  useEffect(() => {
    const uniquePosts = Array.from(new Set(groupPosts.map((p) => p.id))).map(
      (id) => {
        return groupPosts.find((p) => p.id === id);
      }
    );
    setPosts(uniquePosts);
  }, [groupPosts]);

  // adjust the margin top of the list
  const handleMarginTop = (width) => {
    if (width > 991) {
      return "100px";
    } else {
      // check reset state
      if (reset) {
        return "100px";
      } else {
        return "110px";
      }
    }
  };

  // update or create the post in the list
  const postListSetter = (post) => {
    const existingIndex = posts.findIndex((p) => p.id === post.id);
    const updatedPosts = [...posts]; // Create a copy of the array
    if (existingIndex >= 0) {
      // Replace the existing post
      updatedPosts[existingIndex] = post;
    } else {
      // Add the new post
      updatedPosts.unshift(post);
    }

    const sortedPosts = updatedPosts.sort((a, b) => {
      const dateA = new Date(a.posted);
      const dateB = new Date(b.posted);
      return dateB - dateA;
    });
    setPosts(sortedPosts);
  };

  const postListRemover = (postId) => {
    setPosts((prevPosts) => prevPosts.filter((post) => post.id !== postId));
  };

  const handleGoToLogin = () => {
    history.push("/login");
  };

  return (
    <BaseView title={context.t("Group Forum")} backurl="/">
      <hr className="afterTopNavigationBarTwo"></hr>
      {
        <>
          {profile && Object.keys(profile).length > 0 && (
            <Container>
              <PostPosterPanel
                postListSetter={(post) => postListSetter(post)}
                languageId={languageId}
                handleFilterSorter={handleFilterSorter}
                filterSorter={filterSorter}
                resethandler={filterSoterReseter}
              />
            </Container>
          )}
          <Container
            style={{
              color: "black",
              marginTop: handleMarginTop(width),
            }}
          >
            {posts.map((item, index) => {
              if (posts.length === index + 1) {
                return (
                  <Row key={index}>
                    <Col xs={12} md={12} lg={12} ref={lastPostRef}>
                      <PostItem
                        id={item.id}
                        profile={profile}
                        lang={lang}
                        languageId={languageId}
                        postListSetter={(post) => postListSetter(post)}
                        item={item}
                        postListRemover={(postId) => postListRemover(postId)}
                      />
                    </Col>
                  </Row>
                );
              } else {
                return (
                  <Row key={index}>
                    <Col xs={12} md={12} lg={12}>
                      <PostItem
                        id={item.id}
                        profile={profile}
                        lang={lang}
                        languageId={languageId}
                        postListSetter={(post) => postListSetter(post)}
                        item={item}
                        postListRemover={(postId) => postListRemover(postId)}
                      />
                    </Col>
                  </Row>
                );
              }
            })}
            <div className="mt-4" style={{ paddingTop: "10px" }}>
              {loading ? context.t("Loading..") : ""}
            </div>
          </Container>
          {posts.length == 0 && !loading && (
            <div className="mt-4">
              <FilterResultsNotFound text={context.t("No posts found!")} />
            </div>
          )}
          {profile && Object.keys(profile).length === 0 && (
            <Button
              className="mt-3 button-modified"
              variant="outline-primary"
              onClick={handleGoToLogin}
            >
              {context.t("Please login or register new account")}
              &nbsp;
              <AiOutlineArrowRight />
            </Button>
          )}
        </>
      }
    </BaseView>
  );
}

PostListView.contextTypes = {
  t: PropTypes.func.isRequired,
};
