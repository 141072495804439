import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from 'prop-types'

import Select from 'react-select';

import { getStates } from '../../common/redux/actions/StatesActions';
import { reactSelectDropdownStyle } from '../../common/styles/dropdowns';


const StateSelect = (props, context) => {
  const dispatch = useDispatch()

  const states = useSelector(state => state.StatesState)

  const [stateOptions, setStateOptions] = useState([])
  const [stateFilteredOptions, setStateFilteredOptions] = useState([])
  const [selectedState, setSelectedState] = useState(props.initialValue)

  useEffect(() => {
    !states.fetched && dispatch(getStates())
  }, []);

  useEffect(() => {
    const stateOptions = Object.keys(states.items).sort(
      (a,b) => states.items[a].name > states.items[b].name? 1 : -1).map(key => {
        return {
          value: states.items[key].state,
          label: states.items[key].name,
          country: states.items[key].country
        }
    })
    setStateOptions(stateOptions)
  }, [states]);

  useEffect(() => {
    filterStates()
    if (states.items[selectedState] && props.country !== states.items[selectedState].country) {
      setSelectedState(null)
      if (props.onChange) {
        props.onChange(null)
      }
    }
  }, [props.country, stateOptions])

  const filterStates = () => {
    if (props.dependant) {
      if (props.country) {
        const filteredOptions = stateOptions.filter(state => state.country === props.country)
        setStateFilteredOptions(filteredOptions)
      }
    } else {
      setStateFilteredOptions(stateOptions)
    }
  }

  const handleStateChange = (data, event) => {
    const value = data? data.value : null
    setSelectedState(value)
    if (props.onChange) {
      props.onChange(value)
    }
  }

  return (
    <Select
      name='state'
      options={stateFilteredOptions}
      value={stateOptions.filter(o => o.value === selectedState)[0] || null}
      placeholder={props.placeholder}
      style={{textAlign:'center'}}
      onChange={handleStateChange}
      styles={reactSelectDropdownStyle}
      isSearchable={props.isSearchable}
      isMulti={props.isMulti}
      isClearable={props.isClearable}
    />
  )
}

StateSelect.contextTypes = {
  t: PropTypes.func.isRequired
}

export default StateSelect;
