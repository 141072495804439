import React from "react";
import PropTypes from 'prop-types'
import { Container, Row, Col } from 'react-bootstrap';
import {
  FacebookShareButton, FacebookIcon,
  FacebookMessengerShareButton, FacebookMessengerIcon,
  LineShareButton, LineIcon,
  TwitterShareButton, TwitterIcon,
  ViberShareButton, ViberIcon,
} from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";


const ShareButtons = (props, context) => {
  const url = window.location.href
  return (
    <Container>
      <p style={{ marginBottom: 4, fontSize: '0.85em' }}> {context.t('Share')} </p>
      <div style={{ display: "flex", justifyContent: 'center', marginBottom: "60px", marginTop: "17px" }}>
        <div style={{ marginRight: '6px' }}>
          <FacebookShareButton url={window.location.href} quote={props.title} hashtag="#goldendreams">
            <FacebookIcon size={26} round={true} />
          </FacebookShareButton>
        </div>
        <div style={{ marginRight: '6px' }}>
          <ViberShareButton url={window.location.href} title={props.title && props.title.slice(0, 198 - window.location.href.length)} separator=': '>
            <ViberIcon size={26} round={true} />
          </ViberShareButton>
        </div>
        <div style={{ marginRight: '6px' }}>
          <LineShareButton url={window.location.href} title={props.title}>
            <LineIcon size={26} round={true} />
          </LineShareButton>
        </div>
        <div style={{ marginRight: '6px' }}>
          <TwitterShareButton url={window.location.href} title={props.title} hashtags={['goldendreams']}>
            <TwitterIcon size={26} round={true} />
          </TwitterShareButton>
        </div>
        {/* <div style={{ marginRight: '6px' }}>
          <FacebookMessengerShareButton url={window.location.href} appId={process.env.REACT_APP_FACEBOOK_CLIENT_ID}>
            <FacebookMessengerIcon size={26} round={true} />
          </FacebookMessengerShareButton>
        </div> */}
        <div style={{ marginRight: '6px' }}>
          <CopyToClipboard text={url}>
            <button style={{ border: "none", background: "#fff", margin: 0, padding: 0 }}><i className="far fa-copy circle-icon"></i></button>
          </CopyToClipboard>
        </div>
      </div>
    </Container>
  )
}

ShareButtons.contextTypes = {
  t: PropTypes.func.isRequired
}

export default ShareButtons;
