import axios from 'axios';


class SlackLogger {
  constructor() {
    // create axios instance
    this.logger = axios.create();
    delete this.logger.defaults.headers.common["Content-Type"]
    delete this.logger.defaults.headers.common["Authorization"]
    // slack webhook
    this.webhook = process.env.REACT_APP_SLACK_WEBHOOK
  }

  composeReactCrashMessage = (error) => {
    const slackMessage = {
      "blocks": [
        {
          "type": "section",
          "text": {
            "type": "mrkdwn",
            "text": "-----------------------------------------------------------\n*React app crashed (client)*"
          }
        },
        {
          "type": "section",
          "text": {
            "type": "mrkdwn",
            "text": `*Stack:*\n${error.stack}}`
          }
        },
      ]
    }
    return JSON.stringify(slackMessage);
  }

  sendReactCrashed = (error) => {
    const message = this.composeReactCrashMessage(error)
    this.logger.post(this.webhook, message)
	}
}

const slackLogger = new SlackLogger();
export default slackLogger
