import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { useHistory } from "react-router-dom";
import Toast from "react-bootstrap/Toast";

import { hidePushNotificationToast } from "../common/redux/actions/PushNotificationActions";
import { setScrollToIndex } from "../common/redux/actions/FiltersSortersActions";

export default function PushNotificationToast(props, context) {
  const dispatch = useDispatch();
  const history = useHistory();

  const language = useSelector((state) => state.i18nState.lang);

  const pushNotification = useSelector((state) => state.PushNotificationsState);

  const handleClick = () => {
    pushNotification.data.id &&
      dispatch(setScrollToIndex("notifications", pushNotification.data.id));
    history.push(pushNotification.data.click_action);
    dispatch(hidePushNotificationToast());
  };

  return (
    <Toast
      show={pushNotification.show}
      onClick={handleClick}
      onClose={() => dispatch(hidePushNotificationToast())}
      style={{ position: "fixed", bottom: 40, right: 10, zIndex: 10 }}
      delay={8000}
      autohide
    >
      <Toast.Header closeButton={false}>
        <strong className="mr-auto"> {pushNotification.data.title} </strong>
        {/* }<small>11 mins ago</small> */}
      </Toast.Header>
      <Toast.Body
        style={{
          textAlign: "left",
          wordBreak: language === "en" && "break-word",
        }}
      >
        {pushNotification.data.body}
      </Toast.Body>
    </Toast>
  );
}

PushNotificationToast.contextTypes = {
  t: PropTypes.func.isRequired,
};
