import React, { Component } from 'react';
import PropTypes from 'prop-types'

import { connect } from 'react-redux';
import { Row, Col, Form, Button } from 'react-bootstrap';

import CustomDatePicker from '../../components/CustomDatePicker';
import { validateInput } from '../../common/utilities/validation';
import { dateParser } from '../../common/utilities/parsers';
import { authSignup } from '../../common/redux/actions/AuthenticationActions';

import { getCountries } from '../../common/redux/actions/CountriesActions';
import { getNationalities } from '../../common/redux/actions/NationalitiesActions';


class RecruitmentAgenciesRegistrationForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      payload: {
        date_of_registration: ''
      },
      errors: {},
      datepicker: {
        isOpen: false,
      },
    };
  }

  componentDidMount() {
    !this.props.countriesFetched && this.props.getCountries();
    !this.props.nationalitiesFetched && this.props.getNationalities()
  }

  /* BASE HANDLERS */

  handleChange = event => {
      const name = event.target.name;
      const value = event.target.value;
      const error = validateInput(name, value)
      this.setState({
        payload: {
            ...this.state.payload,
            [name]: value,
        },
        errors: {
            ...this.state.errors,
            [name]: error,
        }
      });
  }

  handleSelectChange = (data, event) => {
    const name = event.name;
    const value = data.value;
    const error = validateInput(name, value)
    this.setState({
      payload: {
          ...this.state.payload,
          [name]: value,
      },
      errors: {
          ...this.state.errors,
          [name]: error,
      }
    });
  }

  /* DATEPICKER HANDLERS */

  handleDateChange = (time) => {
    this.setState({
      payload: {
          ...this.state.payload,
          date_of_registration: time,
      },
      datepicker: {
          isOpen: false,
      }
    });
  }

  handleOpenDatePicker = () => {
    this.setState({
      datepicker: {
          isOpen: true,
      },
    });
	}

	handleCloseDatePicker = () => {
    this.setState({
      datepicker: {
          isOpen: false,
      },
    });
	}


  handleBasicSignup = () => {
    console.log('handle basic signup')
    let payload = {...this.state.payload}
    payload.date_of_registration = dateParser(this.state.payload.date_of_registration)
    console.log(payload)
    this.props.authSignup('RA', payload)
  }


  render() {

    const validated = (
      this.state.payload.name && this.state.payload.registration_number
      && this.state.payload.date_of_registration && this.state.payload.phone_number
      && !this.state.errors.full_name
    )

    const username_password_validated = (
      this.state.payload.username && this.state.payload.password
      && !this.state.errors.username && !this.state.errors.password
    )


    var countries = []
    for (var key in this.props.countriesItems) {
      countries.push({value:key, label:this.props.countriesItems[key].name})
    }

    return (
      <Form className="RegistrationForm" onSubmit={this.handleSubmit}>

        {this.props.error && <p style={{color:'red'}}>{this.props.error.message}</p>}

        <Form.Group controlId="name">
          <Form.Label>Name of recruitment agency</Form.Label>
          <Form.Control
            name="name"
            // placeholder={this.context.t('Name of recruitment agency')}
            onChange={this.handleChange}
          />
        </Form.Group>
        <Row>
          <Col>
            <Form.Group controlId="registration_number">
              <Form.Label>Registration number</Form.Label>
              <Form.Control
                name="registration_number"
                // placeholder={this.context.t('Registration number')}
                onChange={this.handleChange}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="date_of_registration">
              <Form.Label>Date of registration</Form.Label>
              <Form.Control
                readOnly
                name='date_of_registration'
                // placeholder={this.context.t('Date of registration')}
                onClick={this.handleOpenDatePicker}
                value={this.state.payload.date_of_registration && this.state.payload.date_of_registration.toLocaleDateString()}
                style={{backgroundColor:'white'}}
              />
              <CustomDatePicker
                value={this.state.payload.date_of_registration? this.state.payload.date_of_registration : new Date()}
                isOpen={this.state.datepicker.isOpen}
                onSelect={this.handleDateChange}
                onCancel={this.handleCloseDatePicker}
              />
            </Form.Group>
          </Col>
        </Row>

        <Form.Group controlId="phone_number">
          <Form.Label>Phone number</Form.Label>
          <Form.Control
            name="phone_number"
            // placeholder={this.context.t('Phone number')}
            onChange={this.handleChange}
          />
        </Form.Group>

        <Form.Group controlId="username">
          <Form.Label>Username</Form.Label>
          <Form.Control
            name="username"
            className={this.state.errors.username && 'warning'}
            // placeholder={this.context.t('Username')}
            onChange={this.handleChange}
          />
          {this.state.errors.username &&
            <p className='validation-error'>{this.state.errors.username}</p>
          }
        </Form.Group>
        <Form.Group controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            name="password"
            type="password"
            className={this.state.errors.password && 'warning'}
            // placeholder={this.context.t('Password')}
            onChange={this.handleChange}
          />
          {this.state.errors.password &&
            <p className='validation-error'>{this.state.errors.password}</p>
          }
        </Form.Group>
        <Button className='mb-6 float-right' onClick={this.handleBasicSignup} disabled={!validated | !username_password_validated}> Register </Button>
      </Form>

    )
  }
}

RecruitmentAgenciesRegistrationForm.contextTypes = {
  t: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  return {
    lang: state.i18nState.lang,
    error: state.AuthenticationState.errors.signup,
    countriesItems: state.CountriesState.items,
    countriesFetched: state.CountriesState.fetched,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    authSignup: (type, payload) => dispatch(
      authSignup(type, payload)
    ),
    getCountries: () => dispatch(getCountries()),
    getNationalities: () => dispatch(getNationalities()),
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(RecruitmentAgenciesRegistrationForm);
