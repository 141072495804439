import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from "react-redux";

import I18n from 'redux-i18n';
import Rater from 'react-rater'
import 'react-rater/lib/react-rater.css'
import { Row, Col } from 'react-bootstrap';

import { getRatingCriteries } from '../common/redux/actions/RatingCriteriesActions';


export default function DetailedRatings(props, context) {
  const dispatch = useDispatch()

  const ratingCriteries = useSelector(state => state.RatingCriteriesState)

  useEffect(() => {
    if (!ratingCriteries.fetched) {
      dispatch(getRatingCriteries())
    }
  }, []);

  return (
    <div className='mt-3' style={{textAlign: 'left'}}>
      <Row>
        <Col xs={7}>
          <p style={{display: 'inline', margin: 0, fontWeight: 500}}> {context.t('Overall:')} </p>
        </Col>
        <Col xs={5}>
          <Rater rating={props.overall_score} total={5} style={{display: 'inline', fontSize: '1.1rem'}} interactive={false}/>
          <span> {props.overall_score && props.overall_score.toFixed(1) || '0.0'} </span>
        </Col>
      </Row>
      {
        props.scores_for_each_criteria.map((item, key) => {
          const ratingCriteria = ratingCriteries.items[item.rating_criteria_id]

          return (
            <Row key={key}>
              <Col xs={7}>
                <p style={{display: 'inline', margin: 0}}> {ratingCriteria && ratingCriteria.name}: </p>
              </Col>
              <Col xs={5}>
                <Rater rating={item.score} total={5} style={{display: 'inline', fontSize: '1.1rem'}} interactive={false}/>
                <span> {item.score.toFixed(1)} </span>
              </Col>
            </Row>
          )
        })
      }
    </div>
  )
}

DetailedRatings.contextTypes = {
  t: PropTypes.func.isRequired
}

DetailedRatings.defaultProps = {
  scores_for_each_criteria: [],
}
