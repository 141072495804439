import React, { useState, useEffect } from "react";
import Select from "react-select";
import PropTypes from "prop-types";

import { CountryCodes } from "../../common/utilities/choices";

const CountryCodesSelect = (props, context) => {
  const countryCodeOptions = CountryCodes(context);
  const [selectedCountryCode, setSelectedCountryCode] = useState(
    countryCodeOptions[0]
  );

  useEffect(() => {
    setSelectedCountryCode(props.initialValue);
  }, [props.initialValue]);

  const handleCountryCodeChange = (data, event) => {
    if (props.onChange) {
      props.onChange(data.value);
    }
  };

  return (
    <Select
      name="country_code"
      options={countryCodeOptions}
      placeholder={props.placeholder}
      value={
        countryCodeOptions.filter((o) => o.value == selectedCountryCode)[0]
      }
      onChange={handleCountryCodeChange.bind(this)}
    />
  );
};

CountryCodesSelect.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default CountryCodesSelect;
