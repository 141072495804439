import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import axios from "axios";

export default function useEmployerRosterSearch(query, cursor) {
  const language = useSelector((state) => state.i18nState.lang);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [employerRoster, setEmployerRoster] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const next = useRef(
    `${process.env.REACT_APP_API_V2_URL}/employers?lang=${language}`
  );

  useEffect(() => {
    setEmployerRoster([]);

    const baseUrl = `${process.env.REACT_APP_API_V2_URL}/employers?lang=${language}&roster=true`;
    let nextUrl = baseUrl;

    if (query?.sort) {
      nextUrl += `&ordering=${query.sort}`;
    }
    if (query?.filter) {
      nextUrl += `&country=${query.filter}`;
    }
    if (query?.search) {
      nextUrl += `&search=${query.search}`;
    }

    next.current = nextUrl;
  }, [query, language]);

  useEffect(() => {
    let cancel;
    setError(false);
    setLoading(true);
    axios({
      method: "GET",
      url: next.current,
      // params: params,
      cancelToken: new axios.CancelToken((c) => (cancel = c)),
    })
      .then((res) => {
        setError(false);
        setLoading(false);
        let employerRoster = [];
        Object.keys(res.data.results).map((key) => {
          employerRoster.push(res.data.results[key]);
        });

        setEmployerRoster((prevEmployerRoster) => [
          ...prevEmployerRoster,
          ...employerRoster,
        ]);

        if (res.data.next) {
          setHasMore(true);
          next.current = res.data.next;
        } else {
          setHasMore(false);
        }
      })
      .catch((e) => {
        if (axios.isCancel(e)) return;
        setError(true);
      });

    return () => cancel();
  }, [query, cursor]);

  return { next, loading, error, employerRoster, hasMore };
}
