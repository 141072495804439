import React, { useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Form, Modal, OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import "./CommentEditorModal.css";
import { AiOutlineEdit } from "react-icons/ai";
import { toast } from "react-toastify";

const CommentEditorModal = (props, context) => {
  const comment = props.comment;
  const comment_body = props.comment_body;
  const handleUpdateCommentList = props.handleUpdateCommentList;
  const post_id = props.post_id;
  const [showModal, setShowModal] = useState(false);
  const [commentBody, setCommentBody] = useState(comment_body);
  const handleBodyChange = (event) => {
    event.preventDefault();
    setCommentBody(event.target.value);
  };

  const handleUpdateComment = async () => {
    const formData = new FormData();
    formData.append("comment_body", commentBody);
    formData.append("comment_id", comment.id);
    const url = `${process.env.REACT_APP_API_URL}/discussion-forum/posts/${post_id}/edit-comment/`;
    try {
      const response = await axios.patch(url, formData);
      handleUpdateCommentList(response.data.comment);
      toast.success("Successfully updated comment !");
      setShowModal(false);
    } catch (error) {
      console.error(error); // Handle any errors
    }
  };

  const handleClose = (e) => {
    e.preventDefault();
    setShowModal(false);
    setCommentBody(comment_body);
  };

  const handleToggleModal = (e) => {
    e.preventDefault();
    setShowModal(!showModal);
  };

  const renderTooltip = (props) => (
    <Tooltip id="my-tooltip" {...props}>
      {context.t("Edit post")}
    </Tooltip>
  );

  return (
    <>
      <OverlayTrigger placement="top" overlay={renderTooltip}>
        <Button
          size="sm"
          variant="outline-primary"
          onClick={handleToggleModal.bind(this)}
        >
          <AiOutlineEdit /> Edit Comment
        </Button>
      </OverlayTrigger>

      <Modal onClick={(e) => e.preventDefault()} centered show={showModal}>
        <Modal.Header className="modal-comment-edit-header" closeButton={false}>
          <Modal.Title>{context.t("Comment Editor")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="commentBody">
            <Form.Label>{context.t("Update your comment")}</Form.Label>
            <Form.Control
              type="text"
              autoFocus
              placeholder={context.t("Write your updated comment here ...")}
              value={commentBody}
              onClick={(e) => e.preventDefault()}
              onChange={handleBodyChange.bind(this)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose.bind(this)}>
            {context.t("Close")}
          </Button>
          <Button
            disabled={commentBody == "" ? true : false}
            variant="primary"
            onClick={handleUpdateComment}
          >
            {context.t("Save Changes")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CommentEditorModal;

CommentEditorModal.contextTypes = {
  t: PropTypes.func.isRequired,
};
