import React, { Component } from "react";
import PropTypes from "prop-types";

import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";

import BaseView from "./BaseView";
import EmployersRegistrationForm from "./Employers/EmployersRegistrationForm";
import JobseekersRegistrationForm from "./Jobseekers/JobseekersRegistrationForm";
import RecruitmentAgenciesRegistrationForm from "./RecruitmentAgencies/RecruitmentAgenciesRegistrationForm";

class RegistrationView extends Component {
  render() {
    return (
      <BaseView title={this.context.t("Registration")} backurl="/login">
        {/* <Jumbotron className='Hero afterTopNavigation'>
          <h3>{this.context.t('Register to apply')}</h3>
        </Jumbotron> */}
        {/*<ButtonGroup className='pt-3 afterTopNavigationBar'>
          <Link
            to='/registration/jobseeker'
            className={'btn btn-info '
              + (this.props.location.pathname === '/registration/jobseeker'
              && 'active focus')}
            > {this.context.t('Jobseeker')}
          </Link>*
          <Link
            to='/registration/recruitment-agency'
            className={'btn btn-info '
              + (this.props.location.pathname === '/registration/recruitment-agency'
              && 'active focus')}
            > {this.context.t('Recruitment Agency')}
          </Link>
          <Link
            to='/registration/employer'
            className={'btn btn-info '
              + (this.props.location.pathname === '/registration/employer'
              && 'active focus')}
            > {this.context.t('Employer')}
          </Link>
        </ButtonGroup>*/}
        {/* TODO: Delete commented out code and removed unused registration routes */}
        <Switch>
          <Route
            exact
            path="/registration/employer"
            component={EmployersRegistrationForm}
          />
          <Route
            exact
            path="/registration/jobseeker"
            component={JobseekersRegistrationForm}
          />
          <Route
            exact
            path="/registration/recruitment-agency"
            component={RecruitmentAgenciesRegistrationForm}
          />
        </Switch>
      </BaseView>
    );
  }
}

RegistrationView.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    lang: state.i18nState.lang,
  };
};

export default connect(mapStateToProps)(RegistrationView);
