import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import moment from "moment";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Image,
  Table,
  Card,
} from "react-bootstrap";
import axios from "axios";

import BaseView from "../BaseView";
import Divider from "../../components/Divider";
import { getCities } from "../../common/redux/actions/CitiesActions";
import { getStates } from "../../common/redux/actions/StatesActions";
import { getCountries } from "../../common/redux/actions/CountriesActions";
import { getNationalities } from "../../common/redux/actions/NationalitiesActions";
import { getIndustries } from "../../common/redux/actions/IndustriesActions";
import { getLanguages } from "../../common/redux/actions/LanguagesActions";
import { getEducationLevels } from "../../common/redux/actions/EducationLevelsActions";
import {
  Genders,
  LanguageProficiencyLevels,
} from "../../common/utilities/choices";
import {
  readSkillLevels,
  speakSkillLevels,
  langaugeSkills,
} from "../../common/utilities/utilities";

import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

export default function JobseekersDetailedResumeView(props, context) {
  const history = useHistory();
  const dispatch = useDispatch();

  const profile = useSelector((state) => state.ProfileState.data);
  const language = useSelector((state) => state.i18nState.lang);
  const cities = useSelector((state) => state.CitiesState);
  const states = useSelector((state) => state.StatesState);
  const countries = useSelector((state) => state.CountriesState);
  const nationalities = useSelector((state) => state.NationalitiesState);
  const genders = Genders({}, context, false);
  const industries = useSelector((state) => state.IndustriesState);
  const languages = useSelector((state) => state.LanguagesState);
  const languageProficiencyLevels = LanguageProficiencyLevels(
    {},
    context,
    false
  );
  const educationLevels = useSelector((state) => state.EducationLevelsState);
  const [documents, setDocuments] = useState(null);
  const [currentCountry, setCurrentCountry] = useState(null);
  const [countryObj, setCountryObj] = useState({});
  const [userResume, setUserResume] = useState(null);
  const [resumeLangSkills, setResumeLangSkills] = useState([]);

  useEffect(() => {
    let isMounted = true;

    if (profile) {
      axios
        .get(
          `${process.env.REACT_APP_API_V2_URL}/jobseekers/${profile.id}/resume`
        )
        .then((res) => {
          if (isMounted && res.data.resume) {
            setUserResume(res.data.resume);
            setResumeLangSkills(listObjToArr(res.data.resume.languages));
          }
        })
        .catch((err) => {
          console.error("Failed to fetch resume", err);
        });
    }
    return () => {
      isMounted = false;
    };
  }, [profile]);

  // Khmer and Myanmar
  const exceptCountries = [2, 3];

  useEffect(() => {
    !cities.fetched && dispatch(getCities());
    !states.fetched && dispatch(getStates());
    !countries.fetched && dispatch(getCountries());
    !industries.fetched && dispatch(getIndustries());
    !languages.fetched && dispatch(getLanguages());
    !nationalities.fetched && dispatch(getNationalities());
    !educationLevels.fetched && dispatch(getEducationLevels());
  }, []);

  function listObjToArr(Objs) {
    if (Object.getOwnPropertyNames(Objs).length > 0) {
      let converted = [];
      Object.values(Objs).forEach((value) => converted.push(value));
      return converted;
    } else {
      return [];
    }
  }

  useEffect(() => {
    if (userResume?.country) {
      setCurrentCountry(userResume.country);
    }
  }, [userResume]);

  useEffect(() => {
    if (countries.fetched) {
      setCountryObj(countries.items[currentCountry]);
    }
  }, [currentCountry]);

  useEffect(() => {
    if (countryObj && Object.keys(countryObj).length != 0) {
      switch (countryObj.code) {
        case "mm":
          setDocuments(myanmarDocs);
          break;
        case "kh":
          setDocuments(combodianDocs);
          break;
        case "ne":
          setDocuments(nepalDocs);
          break;
        default:
          setDocuments(walkInDocs);
          break;
      }
    }
  }, [countryObj]);

  const nepalDocs = () => {
    return (
      <>
        <Row>
          <Col>
            <div className="mt-4" />
            <Divider
              text={context.t("Uploaded Nepal Documents")}
              style={{ fontWeight: 500 }}
            />
            <Row>
              <Col>
                <label htmlFor="passportImage">
                  <Card>
                    <Card.Header>
                      <Form.Label>{context.t("Passport")}</Form.Label>
                    </Card.Header>
                    <Card.Body>
                      <Zoom>
                        <Image
                          src={
                            profile.passport ||
                            "/static/media/ImagePlaceholder.png"
                          }
                          fallback={"/static/media/ImagePlaceholder.png"}
                          crossOrigin="anonymous"
                          style={{
                            width: "120px",
                            height: "120px",
                            objectFit: "cover",
                          }}
                        />
                      </Zoom>
                    </Card.Body>
                  </Card>
                </label>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  };

  const myanmarDocs = () => {
    return (
      <>
        <Row>
          <Col>
            <div className="mt-4" />
            <Divider
              text={context.t("Uploaded Myanmar Documents")}
              style={{ fontWeight: 500 }}
            />
            <Row>
              <Col>
                <label htmlFor="passportImage">
                  <Card>
                    <Card.Header>
                      <Form.Label>{context.t("NRC Card")}</Form.Label>
                    </Card.Header>
                    <Card.Body>
                      <Zoom>
                        <Image
                          src={
                            profile.nrc || "/static/media/ImagePlaceholder.png"
                          }
                          fallback={"/static/media/ImagePlaceholder.png"}
                          crossOrigin="anonymous"
                          style={{
                            width: "120px",
                            height: "120px",
                            objectFit: "cover",
                          }}
                        />
                      </Zoom>
                    </Card.Body>
                  </Card>
                </label>
              </Col>
              <Col>
                <label htmlFor="passportImage">
                  <Card>
                    <Card.Header>
                      <Form.Label>{context.t("Household List")}</Form.Label>
                    </Card.Header>
                    <Card.Body>
                      <Zoom>
                        <Image
                          src={
                            profile.householdlist ||
                            "/static/media/ImagePlaceholder.png"
                          }
                          fallback={"/static/media/ImagePlaceholder.png"}
                          crossOrigin="anonymous"
                          style={{
                            width: "120px",
                            height: "120px",
                            objectFit: "cover",
                          }}
                        />
                      </Zoom>
                    </Card.Body>
                  </Card>
                </label>
              </Col>

              <Col>
                <label htmlFor="passportImage">
                  <Card>
                    <Card.Header>
                      <Form.Label>{context.t("Passport (PJ)")}</Form.Label>
                    </Card.Header>
                    <Card.Body>
                      <Zoom>
                        <Image
                          src={
                            profile.passport ||
                            "/static/media/ImagePlaceholder.png"
                          }
                          fallback={"/static/media/ImagePlaceholder.png"}
                          crossOrigin="anonymous"
                          style={{
                            width: "120px",
                            height: "120px",
                            objectFit: "cover",
                          }}
                        />
                      </Zoom>
                    </Card.Body>
                  </Card>
                </label>
              </Col>

              <Col>
                <label htmlFor="passportImage">
                  <Card>
                    <Card.Header>
                      <Form.Label>{context.t("Labour Card")}</Form.Label>
                    </Card.Header>
                    <Card.Body>
                      <Zoom>
                        <Image
                          src={
                            profile.labourcard ||
                            "/static/media/ImagePlaceholder.png"
                          }
                          fallback={"/static/media/ImagePlaceholder.png"}
                          crossOrigin="anonymous"
                          style={{
                            width: "120px",
                            height: "120px",
                            objectFit: "cover",
                          }}
                        />
                      </Zoom>
                    </Card.Body>
                  </Card>
                </label>
              </Col>

              <Col>
                <label htmlFor="passportImage">
                  <Card>
                    <Card.Header>
                      <Form.Label>
                        {context.t("Covid Vaccine Certificate")}
                      </Form.Label>
                    </Card.Header>
                    <Card.Body>
                      <Zoom>
                        <Image
                          src={
                            profile.covidvaccinecertificate ||
                            "/static/media/ImagePlaceholder.png"
                          }
                          fallback={"/static/media/ImagePlaceholder.png"}
                          crossOrigin="anonymous"
                          style={{
                            width: "120px",
                            height: "120px",
                            objectFit: "cover",
                          }}
                        />
                      </Zoom>
                    </Card.Body>
                  </Card>
                </label>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  };

  const combodianDocs = () => {
    return (
      <>
        <Row>
          <Col>
            <div className="mt-4" />
            <Divider
              text={context.t("Uploaded Khmer Documents")}
              style={{ fontWeight: 500 }}
            />
            <Row>
              <Col>
                <label htmlFor="passportImage">
                  <Card>
                    <Card.Header>
                      <Form.Label>{context.t("Passport")}</Form.Label>
                    </Card.Header>
                    <Card.Body>
                      <Zoom>
                        <Image
                          src={
                            profile.passport ||
                            "/static/media/ImagePlaceholder.png"
                          }
                          fallback={"/static/media/ImagePlaceholder.png"}
                          crossOrigin="anonymous"
                          style={{
                            width: "120px",
                            height: "120px",
                            objectFit: "cover",
                          }}
                        />
                      </Zoom>
                    </Card.Body>
                  </Card>
                </label>
              </Col>
              <Col>
                <label htmlFor="passportImage">
                  <Card>
                    <Card.Header>
                      <Form.Label>
                        {context.t("Identification Card")}
                      </Form.Label>
                    </Card.Header>
                    <Card.Body>
                      <Zoom>
                        <Image
                          src={
                            profile.idcard ||
                            "/static/media/ImagePlaceholder.png"
                          }
                          fallback={"/static/media/ImagePlaceholder.png"}
                          crossOrigin="anonymous"
                          style={{
                            width: "120px",
                            height: "120px",
                            objectFit: "cover",
                          }}
                        />
                      </Zoom>
                    </Card.Body>
                  </Card>
                </label>
              </Col>

              <Col>
                <label htmlFor="passportImage">
                  <Card>
                    <Card.Header>
                      <Form.Label>{context.t("Birth Certificate")}</Form.Label>
                    </Card.Header>
                    <Card.Body>
                      <Zoom>
                        <Image
                          src={
                            profile.birthcertificate ||
                            "/static/media/ImagePlaceholder.png"
                          }
                          fallback={"/static/media/ImagePlaceholder.png"}
                          crossOrigin="anonymous"
                          style={{
                            width: "120px",
                            height: "120px",
                            objectFit: "cover",
                          }}
                        />
                      </Zoom>
                    </Card.Body>
                  </Card>
                </label>
              </Col>

              <Col>
                <label htmlFor="passportImage">
                  <Card>
                    <Card.Header>
                      <Form.Label>{context.t("Family Record Book")}</Form.Label>
                    </Card.Header>
                    <Card.Body>
                      <Zoom>
                        <Image
                          src={
                            profile.familyrecordbook ||
                            "/static/media/ImagePlaceholder.png"
                          }
                          fallback={"/static/media/ImagePlaceholder.png"}
                          crossOrigin="anonymous"
                          style={{
                            width: "120px",
                            height: "120px",
                            objectFit: "cover",
                          }}
                        />
                      </Zoom>
                    </Card.Body>
                  </Card>
                </label>
              </Col>

              <Col>
                <label htmlFor="passportImage">
                  <Card>
                    <Card.Header>
                      <Form.Label>{context.t("Residence Book")}</Form.Label>
                    </Card.Header>
                    <Card.Body>
                      <Zoom>
                        <Image
                          src={
                            profile.residencebook ||
                            "/static/media/ImagePlaceholder.png"
                          }
                          fallback={"/static/media/ImagePlaceholder.png"}
                          crossOrigin="anonymous"
                          style={{
                            width: "120px",
                            height: "120px",
                            objectFit: "cover",
                          }}
                        />
                      </Zoom>
                    </Card.Body>
                  </Card>
                </label>
              </Col>
              <Col>
                <label htmlFor="passportImage">
                  <Card>
                    <Card.Header>
                      <Form.Label>
                        {context.t("Covid Vaccine Certificate")}
                      </Form.Label>
                    </Card.Header>
                    <Card.Body>
                      <Zoom>
                        <Image
                          src={
                            profile.covidvaccinecertificate ||
                            "/static/media/ImagePlaceholder.png"
                          }
                          fallback={"/static/media/ImagePlaceholder.png"}
                          crossOrigin="anonymous"
                          style={{
                            width: "120px",
                            height: "120px",
                            objectFit: "cover",
                          }}
                        />
                      </Zoom>
                    </Card.Body>
                  </Card>
                </label>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  };

  const walkInDocs = () => {
    return (
      <>
        <Row>
          <Col>
            <div className="mt-4" />
            <Divider
              text={context.t("Uploaded Documents")}
              style={{ fontWeight: 500 }}
            />
            <Row>
              <Col>
                <label htmlFor="passportImage">
                  <Card>
                    <Card.Header>
                      <Form.Label>{context.t("Passport")}</Form.Label>
                    </Card.Header>
                    <Card.Body>
                      <Zoom>
                        <Image
                          src={
                            profile.passport ||
                            "/static/media/ImagePlaceholder.png"
                          }
                          fallback={"/static/media/ImagePlaceholder.png"}
                          crossOrigin="anonymous"
                          style={{
                            width: "120px",
                            height: "120px",
                            objectFit: "cover",
                          }}
                        />
                      </Zoom>
                    </Card.Body>
                  </Card>
                </label>
              </Col>
              <Col>
                <label htmlFor="passportImage">
                  <Card>
                    <Card.Header>
                      <Form.Label>{context.t("Visa")}</Form.Label>
                    </Card.Header>
                    <Card.Body>
                      <Zoom>
                        <Image
                          src={
                            profile.visa || "/static/media/ImagePlaceholder.png"
                          }
                          fallback={"/static/media/ImagePlaceholder.png"}
                          crossOrigin="anonymous"
                          style={{
                            width: "120px",
                            height: "120px",
                            objectFit: "cover",
                          }}
                        />
                      </Zoom>
                    </Card.Body>
                  </Card>
                </label>
              </Col>

              <Col>
                <label htmlFor="passportImage">
                  <Card>
                    <Card.Header>
                      <Form.Label>{context.t("Work Permit")}</Form.Label>
                    </Card.Header>
                    <Card.Body>
                      <Zoom>
                        <Image
                          src={
                            profile.workpermit ||
                            "/static/media/ImagePlaceholder.png"
                          }
                          fallback={"/static/media/ImagePlaceholder.png"}
                          crossOrigin="anonymous"
                          style={{
                            width: "120px",
                            height: "120px",
                            objectFit: "cover",
                          }}
                        />
                      </Zoom>
                    </Card.Body>
                  </Card>
                </label>
              </Col>
              <Col>
                <label htmlFor="passportImage">
                  <Card>
                    <Card.Header>
                      <Form.Label>{context.t("Pink Card")}</Form.Label>
                    </Card.Header>
                    <Card.Body>
                      <Zoom>
                        <Image
                          src={
                            profile.pinkcard ||
                            "/static/media/ImagePlaceholder.png"
                          }
                          fallback={"/static/media/ImagePlaceholder.png"}
                          crossOrigin="anonymous"
                          style={{
                            width: "120px",
                            height: "120px",
                            objectFit: "cover",
                          }}
                        />
                      </Zoom>
                    </Card.Body>
                  </Card>
                </label>
              </Col>

              <Col>
                <label htmlFor="passportImage">
                  <Card>
                    <Card.Header>
                      <Form.Label>
                        {context.t("Cancellation letter")}
                      </Form.Label>
                    </Card.Header>
                    <Card.Body>
                      <Zoom>
                        <Image
                          src={
                            profile.cancellationletter ||
                            "/static/media/ImagePlaceholder.png"
                          }
                          fallback={"/static/media/ImagePlaceholder.png"}
                          crossOrigin="anonymous"
                          style={{
                            width: "120px",
                            height: "120px",
                            objectFit: "cover",
                          }}
                        />
                      </Zoom>
                    </Card.Body>
                  </Card>
                </label>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <BaseView title={context.t("Resume")} backurl="/resume">
      <Container className="afterTopNavigationBar">
        <Row style={{ textAlign: "left" }}>
          <Col>
            <div className="mt-4" />
            <Divider
              text={context.t("Basic Information")}
              style={{ fontWeight: 500 }}
            />
            <Row>
              <Col xs={4}>
                <Image
                  src={
                    userResume?.photo ||
                    "/static/media/blank-profile-picture.png"
                  }
                  crossOrigin="anonymous"
                  style={{
                    width: "90px",
                    height: "90px",
                    objectFit: "cover",
                  }}
                />
              </Col>
              <Col>
                <p style={{ marginBottom: 4 }}>
                  <span className="label">{context.t("Full name")}: </span>
                  {userResume?.full_name}
                </p>
                <p style={{ marginBottom: 4 }}>
                  <span className="label">{context.t("Gender")}: </span>
                  {genders[userResume?.gender]}
                </p>
                <p style={{ marginBottom: 4 }}>
                  <span className="label">{context.t("Birthday")}: </span>
                  {moment(userResume?.date_of_birth).format("ll")}
                </p>
                <p>
                  <span className="label">{context.t("Education")}: </span>
                  {educationLevels.items[userResume?.education] &&
                    educationLevels.items[userResume?.education].name}
                </p>
                {educationLevels.items[userResume?.education] &&
                  educationLevels.items[userResume?.education].has_major && (
                    <p style={{ marginTop: "calc(-1rem + 4px)" }}>
                      <span className="label">{context.t("Major")}: </span>
                      {userResume?.major}
                    </p>
                  )}
                <p style={{ marginBottom: 4 }}>
                  <span className="label">{context.t("Nationality")}: </span>
                  {nationalities.items[userResume?.nationality]?.name}
                </p>
              </Col>
            </Row>

            <Divider
              text={context.t("Contact Information")}
              style={{ fontWeight: 500 }}
            />
            <Row>
              <Col>
                <p style={{ marginBottom: 4 }}>
                  <span className="label">{context.t("Email")}: </span>
                  {userResume?.email}
                </p>
                <p style={{ marginBottom: 4 }}>
                  <span className="label">{context.t("Phone number")}: </span>
                  {userResume?.phone_number}
                </p>
                <p style={{ marginBottom: 4 }}>
                  <span className="label">
                    {context.t("Emergency Phone number")}:{" "}
                  </span>
                  {userResume?.emergency_phone_number}
                </p>
                <p style={{ marginBottom: 4 }}>
                  <span className="label">{context.t("LINE id number")}: </span>
                  {userResume?.line_id_number}
                </p>
                <p style={{ marginBottom: 4 }}>
                  <span className="label">
                    {context.t("Viber id number")}:{" "}
                  </span>
                  {userResume?.viber_id_number}
                </p>
                <p>
                  <span className="label">
                    {context.t("Current Address")}:{" "}
                  </span>
                  {[
                    userResume?.address_details,
                    cities.items[userResume?.city] &&
                      cities.items[userResume?.city].name,
                    states.items[userResume?.state] &&
                      states.items[userResume?.state].name,
                    countries.items[userResume?.country] &&
                      countries.items[userResume?.country].name,
                  ]
                    .filter((x) => x)
                    .join(", ")}
                </p>
              </Col>
            </Row>

            <Divider
              text={context.t("Documents")}
              style={{ fontWeight: 500 }}
            />
            <Row>
              <Col>
                <p style={{ marginBottom: 4 }}>
                  <span className="label">{context.t("National ID")}: </span>
                  {userResume?.national_id_number}
                </p>
                <p style={{ marginBottom: 4 }}>
                  <span className="label">{context.t("Passport")}: </span>
                  {userResume?.passport_number}
                </p>
                <p style={{ marginBottom: 4 }}>
                  <span className="label">
                    {context.t("Passport expires")}:{" "}
                  </span>
                  {userResume?.passport_expire_date &&
                    moment(userResume?.passport_expire_date).format("ll")}
                </p>
                <p style={{ marginBottom: 4 }}>
                  <span className="label">
                    {context.t("Certificate of Identity Number")}:{" "}
                  </span>
                  {userResume?.certificate_of_identity_number}
                </p>
                {language !== "kh" && (
                  // not aplicable for Khmer users, just hide it
                  <p style={{ marginBottom: 4 }}>
                    <span className="label">
                      {context.t("Certificate of Identity expires")}:{" "}
                    </span>
                    {userResume?.certificate_of_identity_expire_date &&
                      moment(
                        userResume?.certificate_of_identity_expire_date
                      ).format("ll")}
                  </p>
                )}
                {userResume &&
                  !exceptCountries.includes(userResume?.country) && (
                    <p>
                      <span className="label">
                        {context.t("Pink Card Number")}:{" "}
                      </span>
                      {userResume?.pinkcard_id_number}
                    </p>
                  )}
              </Col>
            </Row>

            <Divider
              text={context.t("Working Experience")}
              style={{ fontWeight: 500 }}
            />
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>#</th>
                  <th>{context.t("Title")}</th>
                  <th>{context.t("Industry")}</th>
                  <th style={{ minWidth: 50 }}>{context.t("Years")}</th>
                </tr>
              </thead>
              <tbody>
                {userResume?.working_experience.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{userResume?.working_experience[index].title}</td>
                    <td>
                      {Object.keys(industries.items).length > 0 &&
                        industries.items[
                          userResume?.working_experience[index].industry_id
                        ].name}
                    </td>
                    <td>{userResume?.working_experience[index].years}</td>
                  </tr>
                ))}
              </tbody>
            </Table>

            <Divider
              text={context.t("Languages Proficiency")}
              style={{ fontWeight: 500 }}
            />
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>#</th>
                  <th>{context.t("Language Skills")}</th>
                  <th>{context.t("Read Level")}</th>
                  <th style={{ minWidth: 50 }}>{context.t("Speak Level")}</th>
                </tr>
              </thead>
              <tbody>
                {resumeLangSkills.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                      {Object.keys(languages.items).length > 0 &&
                        langaugeSkills(context, languages).map((option) => {
                          if (
                            option.value == resumeLangSkills[index].language
                          ) {
                            return option.label;
                          }
                        })}
                    </td>
                    <td>
                      {readSkillLevels(context).map((option) => {
                        if (
                          option.value == resumeLangSkills[index].read_level
                        ) {
                          return option.label;
                        }
                      })}
                    </td>
                    <td>
                      {speakSkillLevels(context).map((option) => {
                        if (
                          option.value == resumeLangSkills[index].speak_level
                        ) {
                          return option.label;
                        }
                      })}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
        {
          // Passport
          // Visa
          // Work Permit
          // Pink card
          // Cancellation letter
        }
        {documents}
      </Container>
    </BaseView>
  );
}

JobseekersDetailedResumeView.contextTypes = {
  t: PropTypes.func.isRequired,
};
