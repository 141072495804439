import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Button, Modal } from "react-bootstrap";
import { getJobs } from "../../common/redux/actions/JobsActions";
import { getStates } from "../../common/redux/actions/StatesActions";
import { getCities } from "../../common/redux/actions/CitiesActions";
import { getIndustries } from "../../common/redux/actions/IndustriesActions";
import { getSubIndustries } from "../../common/redux/actions/SubIndustriesActions";
import { useHistory } from "react-router-dom";
import moment from "moment";

export default function JobsAlertModal(props, context) {
  const dispatch = useDispatch();
  const now = Date();
  const history = useHistory();
  const profile = useSelector((state) => state.ProfileState.data);
  const states = useSelector((state) => state.StatesState);
  const jobs = useSelector((state) => state.JobsState);
  const cities = useSelector((state) => state.CitiesState);
  const [show, setShow] = useState(false);
  const [today, setToday] = useState(moment(moment(now).format("YYYY-MM-DD")));
  const [weeklyJobs, setWeeklyJobs] = useState([]);
  const [unReadJobs, setUnReadJobs] = useState([]);
  const [provinceAndIndustry, setProvinceAndIndustry] = useState([]);
  const industries = useSelector((state) => state.IndustriesState);
  const subindustries = useSelector((state) => state.SubIndustriesState);

  useEffect(() => {
    !jobs.fetched && !jobs.fetching && dispatch(getJobs());
    !states.fetched && !states.loading && dispatch(getStates());
    !cities.fetched && !cities.loading && dispatch(getCities());
    !industries.fetched && !industries.loading && dispatch(getIndustries());
    !subindustries.fetched &&
      !subindustries.loading &&
      dispatch(getSubIndustries());
  }, []);

  // getting unRead jobs
  useEffect(() => {
    if (jobs.fetched && jobs.items.length >= 1) {
      let unReadJobs = [];
      jobs.items.map((job) => {
        if (job.has_new_job_notifications === true) {
          unReadJobs.push(job);
        }
      });
      setUnReadJobs(unReadJobs);
    }
  }, []);

  // filter un read jobs within 7 days
  useEffect(() => {
    if (unReadJobs.length >= 1) {
      let weeklyJobs = [];
      unReadJobs.map((unReadJob) => {
        if (
          today.diff(moment(unReadJob.created_at), "days") <= 7 &&
          today.diff(moment(unReadJob.created_at), "days") >= 0
        ) {
          weeklyJobs.push(unReadJob);
        }
      });
      setWeeklyJobs(weeklyJobs);
    }
  }, [unReadJobs]);

  // get the province and industry from the weekly created - unread jobs
  useEffect(() => {
    if (
      weeklyJobs.length >= 1 &&
      states.fetched &&
      cities.fetched &&
      industries.fetched &&
      subindustries.fetched
    ) {
      let provinceAndIndustry = [];
      weeklyJobs.map((weeklyJob) => {
        let res =
          states.items[weeklyJob.employer_state]?.name +
          "," +
          cities.items[weeklyJob.employer_city]?.name;
        if (subindustries.items[weeklyJob.employer_industry]) {
          res += ` | ${
            industries.items[
              subindustries.items[weeklyJob.employer_industry].industry_id
            ]?.name
          } | ${subindustries.items[weeklyJob.employer_industry]?.name}`;
        }
        provinceAndIndustry.push(res);
      });
      setProvinceAndIndustry(provinceAndIndustry);
    }
  }, [weeklyJobs]);

  useEffect(() => {
    if (provinceAndIndustry.length >= 1) {
      if (!localStorage.getItem("job_alert")) {
        setShow(true);
      } else {
        let job_alert = JSON.parse(localStorage.getItem("job_alert"));
        let last_alerted_date = moment(job_alert.last_alerted);
        let diff_day = today.diff(last_alerted_date, "days");
        if (!job_alert.seen && diff_day == 0) {
          setShow(true);
        } else if (job_alert.seen && diff_day == 7) {
          setShow(true);
        }
      }
    }
  }, [provinceAndIndustry]);

  const handleClose = () => {
    let job_alert = { seen: true, last_alerted: today };
    setShow(false);
    localStorage.setItem("job_alert", JSON.stringify(job_alert));
  };

  const handleCheck = () => {
    history.push("/jobs");
    let job_alert = { seen: true, last_alerted: today };
    setShow(false);
    localStorage.setItem("job_alert", JSON.stringify(job_alert));
  };

  return (
    <div>
      <Modal show={show} centered onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "1rem" }}>
            <b> {context.t("New Jobs Available !")}</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {context.t(
            "There are some jobs available at the marketplace now {provinceAndIndustry}, have you checked them out?",
            { provinceAndIndustry: provinceAndIndustry.join(" , ") }
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {context.t("Close")}
          </Button>
          <Button variant="success" onClick={handleCheck}>
            {context.t("Check")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

JobsAlertModal.contextTypes = {
  t: PropTypes.func.isRequired,
};
