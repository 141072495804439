import React from "react";
import { useSelector } from "react-redux";
import PropTypes from 'prop-types'
import { Link } from "react-router-dom";
import { Image } from 'react-bootstrap';
import Rater from 'react-rater'
import 'react-rater/lib/react-rater.css'
import './RecruitmentAgenciesListItem.css';

import { addDefaultImageSrc } from '../common/utilities/utilities';


const RecruitmentAgenciesListItem = (props, context) => {

  const agency = props.agency;
  const language = useSelector(state => state.i18nState.lang);
  
  const renderName = (id) => {
    if(language == 'en' || language == 'ne'){
       return null;
    }else{
      return <h3 id={id} className='ListItemName'> {agency.name_en}</h3>
    }
  }

  return (
    <Link to={`/recruitment-agencies/${agency.id}`}>
      <div className='Card mt-3' style={{ textAlign: 'center' }}>
        <div style={{ position: 'relative' }}>
          <div className='ra-status'>
            {agency.status === 'Operational' ?
              <i className="fa-regular fa-circle-check solid-icon" style={{ fontSize: '15px', color: 'green', }}></i>
              :
              agency.status === 'Temporarily Suspended' ?
                <i className="far fa-pause-circle solid-icon" style={{ fontSize: '15px', color: 'orange' }}></i>
                :
                agency.status === 'License is not extended' ?
                  <i className="fas fa-id-badge solid-icon" style={{ fontSize: '15px', color: 'darkorange' }}></i>
                  :
                  <i className="fa-sharp fa-regular fa-circle-xmark" style={{ fontSize: '15px', color: 'red' }}></i>
            }
          </div>
          <Image
            src={agency.logo ? agency.logo : '/static/media/recruiter.png'}
            crossOrigin='anonymous'
            onError={addDefaultImageSrc}
            className="img-border" />
          <div className='comments-counter'>
            <i className="far fa-comment-alt"></i>
            <span> {agency.comments_count} </span>
          </div>
        </div>
        <Rater total={5} rating={agency.rating_score} interactive={false} />
        <span>{agency.rating && agency.rating.toFixed(1)}</span>
        <span style={{ position: 'relative', bottom: '7px', fontSize: '0.7em' }}> ({agency.rating_count})</span>
        <h3 className='ListItemName'> {agency.name} </h3>
        {renderName(agency.id)}
      </div>
    </Link>
  )
}

RecruitmentAgenciesListItem.contextTypes = {
  t: PropTypes.func.isRequired
}

export default RecruitmentAgenciesListItem;
