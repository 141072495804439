import React, { useState, useEffect, useRef } from "react";
import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap';


export default function CommentInput(props, context) {
  const inputRef = useRef();

  const [replyState, setReplyState] = useState({
    isReply: props.isReply,
    isModerator: false,
    username: '',
  })

  const [payload, setPayload] = useState({
    text: '',
    user: props.user_id,
    object_id: props.object_id,
    parent: props.parent_id,
    base_parent: props.base_parent_id
  })
  useEffect(() => {
    setPayload({
      ...payload,
      parent: props.parent,
      base_parent: props.base_parent
    })
    setReplyState({
      isReply: props.isReply,
      isModerator: props.replyIsModerator,
      username: props.replyUsername,
    })
  }, [props.parent, props.base_parent, props.isReply]);

  const handleChangeComment = event => {
    setPayload({ ...payload, text: event.target.textContent })
  }

  const handleSubmitComment = event => {
    event.preventDefault();
    props.onCloseReply()
    props.onSubmit(payload)
    setPayload({ ...payload, text: '', parent: null, base_parent: null })
    inputRef.current.innerHTML = '' // clear innerHTML text to display placeholder
  }
  return (
    <div>
      {replyState.isReply &&
        <div className='replyBox'>
          <span> {context.t('Replying to {username}', {username: replyState.isModerator? context.t('moderator') : replyState.username || props.replyToText})} </span>
          <i className="fas fa-times fa-lg" style={{textAligh: 'right', alignSelf: 'center', color: 'red'}} onClick={props.onCloseReply}></i>
        </div>
      }
      <div className='userInput'>
        <span
          ref={inputRef}
          data-content={replyState.isReply? context.t('Write a reply...') : context.t('Write a comment...')}
          className="commentInput align-items-center"
          role="textbox"
          contentEditable
          value={payload.text}
          onInput={handleChangeComment}
          suppressContentEditableWarning={true}
          style={{padding: '8px 42px 8px 8px', borderRadius: '16px', margin: '12px 0'}}
        >
        </span>
        <Button
          variant="link"
          style={{position: 'absolute', bottom: 15, right: 14, fontSize: 13, fontWeight: 500 }}
          disabled={!payload.text}
          onClick={handleSubmitComment}
        >
          {context.t('Post a comment')}
        </Button>
      </div>
    </div>
  )
}


CommentInput.contextTypes = {
  t: PropTypes.func.isRequired
}

CommentInput.defaultProps = {
  isReply: false
};
