import React, { Component } from 'react';

import BaseView from './BaseView';


class NotFoundView extends Component {
  render() {
    return (
      <BaseView title='404 Not Found' backurl='/'>
        <p style={{marginTop:42}}> I'm 404 Not Found page </p>
      </BaseView>
    )
  }
}

export default NotFoundView;
