import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Image } from 'react-bootstrap';
import Rater from 'react-rater'
import 'react-rater/lib/react-rater.css'

import { addDefaultImageSrc } from '../common/utilities/utilities';


const EmployersListItem = (props, context) => {
  
  const employer = props.employer;
  const language = useSelector(state => state.i18nState.lang);
  
  const renderName = (id) => {
    if(language == 'en' || language == 'ne'){
       return null;
    }else{
      return <h3 id={id} className='ListItemName'> {employer.name_en}</h3>
    }
  }
  return (
    <Link to={`/employers/${employer.id}`}>
      <div className='Card mt-3' style={{ textAlign: 'center' }}>
        <div style={{ position: 'relative' }}>
          <div className='ra-status'>
            {employer.status === 'Operational' ?
              <i className="fa-regular fa-circle-check" style={{ color: 'green' }}></i>
              :
              employer.status === 'Temporarily Suspended' ?
                <i className="far fa-pause-circle" style={{ color: 'orange' }}></i>
                :
                employer.status === 'License is not extended' ?
                  <i className="fas fa-id-badge" style={{ color: 'darkorange' }}></i>
                  :
                  <i className="fa-sharp fa-regular fa-circle-xmark" style={{ color: 'red' }}></i>
            }
          </div>
          <Image
            src={employer.logo ? employer.logo : '/static/media/factory.png'}
            crossOrigin='anonymous'
            onError={addDefaultImageSrc}
            className="img-border" />
          <div className='comments-counter'>
            <i className="far fa-comment-alt"></i>
            <span> {employer.comments_count} </span>
          </div>
        </div>
        <Rater total={5} rating={employer.rating_score} interactive={false} />
        <span>{employer.rating && employer.rating.toFixed(1)}</span>
        <span style={{ position: 'relative', bottom: '7px', fontSize: '0.7em' }}> ({employer.rating_count})</span>
        <h3 className='ListItemName'> {employer.name} </h3>
        {renderName(employer.id)}
        <p style={{ marginBottom: 0 }}> {employer.countries} </p>
        <p style={{ fontWeight: 600 }}> {employer.industries} </p>
      </div>
    </Link>
  )
}


export default EmployersListItem;
