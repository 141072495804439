import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from 'prop-types';

import I18n from 'redux-i18n';
import moment from 'moment';

import { FormControl, Button, Modal, Row, Col } from 'react-bootstrap';
import InputGroup from 'react-bootstrap/InputGroup';

import { updateUserNotificationSettings } from '../../common/redux/actions/UsersActions';
import { postPollsVote } from '../../common/redux/actions/PollsActions';
import { messaging } from "../../firebase";


export default function NotificationsModal(props, context) {
  const dispatch = useDispatch()

  const profile = useSelector(state => state.ProfileState.data)
  const polls = useSelector(state => state.PollsState)

  const [show, setShow] = useState(props.show || false)

  const handleClickVoteButton = (e) => {
    const payload = {
      question_id: polls.latest.id,
      choice_id: parseInt(e.target.value)
    }
    dispatch(postPollsVote(payload))
  }

  useEffect(() => {
    setShow(props.show)
  }, [props.show])

  const allowNotifications = () => {
    setShow(false)
    let notifications = {consent: true, date: moment()}
    localStorage.setItem('notifications', JSON.stringify(notifications))

    messaging.requestPermission()
      .then(async () => {
        const token = await messaging.getToken();
        const payload = {firebase_token: token, firebase_enabled: true}
        dispatch(updateUserNotificationSettings(profile.id, payload))
      })
      .catch(function(err) {
        console.log("Unable to get permission to notify.", err);
      })
  }

  const blockNotifications = () => {
    setShow(false)
    let notifications = {consent: false, date: moment()}
    localStorage.setItem('notifications', JSON.stringify(notifications))
  }

  return (
    <Modal show={show} centered onHide={blockNotifications}>
      <Modal.Body style={{display: 'flex', justifyContent: 'center'}}>
        <p style={{margin: 0}}>
          {context.t(
            '{appName} would like to send you notifications for the latest news and updates. Notifications may include alerts, sounds and icon badges.'
           , {appName: <strong>{context.t('Golden Dreams')}</strong>}
         )}
      </p>
      </Modal.Body>
      <Modal.Footer
        style={{borderTop: 'none', paddingTop: 0}}
      >
        <Button variant='outline-secondary' onClick={blockNotifications}>
          {context.t('Block')}
        </Button>
        <Button variant='success' onClick={allowNotifications}>
          {context.t('Allow')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

NotificationsModal.contextTypes = {
  t: PropTypes.func.isRequired
}
