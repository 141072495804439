import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import axios from "axios";
import PropTypes from "prop-types";
import { Image, Button, Modal, Form, Card } from "react-bootstrap";
import { AiOutlineSmile, AiOutlineArrowDown } from "react-icons/ai";
import CommentDeleteModal from "../containers/Forum/CommentDeleteModal";
import CommentEditorModal from "../containers/Forum/CommentEditorModal";
import {
  commentDeletePermissionLogic,
  commentEditPermissionLogic,
} from "../common/utilities/utilities.js";

export default function CommentModal(props, context) {
  const [comments, setComments] = useState([...props.comments]);
  const [comment, setComment] = useState("");
  const commentRef = useRef();
  const post_id = props.post_id;
  const profile = props.profile;
  const data = props.data;
  const commentCountSetter = props.commentCountSetter;
  const closeButtonClick = props.closeButtonClick;

  useEffect(() => {
    setComments(props.comments);
  }, [props.comments]);

  useEffect(() => {
    commentRef.current && commentRef.current.focus();
  }, []);

  const scrollRef = useRef(null);

  const handleScroll = (e) => {
    e.preventDefault();
    scrollRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const onCommentLikeClick = (e) => {
    e.preventDefault();
  };

  const commentInputFocus = (e) => {
    e.preventDefault();
  };

  const handleInputChange = (e) => {
    setComment(e.target.value);
  };

  const postComment = (post_id, e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("comment_body", comment);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/discussion-forum/posts/${post_id}/comment/`,
        formData
      )
      .then((response) => {
        setComments((prevComments) => [...prevComments, response.data.comment]);
        commentCountSetter(post_id);
        commentRef.current.value = "";
        setComment("");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const commentListRemover = (id) => {
    const updatedData = comments.filter((item) => item.id !== id);
    setComments(updatedData);
    props.set_comments_count(comments.length - 1);
  };

  const handleUpdateCommentList = (cmt) => {
    let updatedComments = comments.map((comment) => {
      if (comment.id === cmt.id) {
        return { ...comment, body: cmt.body };
      }
      return comment;
    });

    setComments(updatedComments);
  };

  return (
    <Modal {...props} size="lg" centered dialogClassName="modal-full-width">
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {context.t("Comments")}
        </Modal.Title>
        {comments && comments.length > 5 && (
          <Button
            variant="success"
            style={{ float: "right", border: "1px solid black" }}
            onClick={handleScroll.bind(this)}
          >
            <AiOutlineArrowDown />
            &nbsp;
            {context.t("Go to Last Comment")}
          </Button>
        )}
      </Modal.Header>
      <Modal.Body>
        {data.fetching && <div>{context.t("Fetching comments ...")}</div>}
        {!data.fetching && comments && comments.length == 0 && (
          <center>
            <p>
              {context.t("Be a first person to comment on this post")}
              &nbsp;&nbsp;
              <AiOutlineSmile />
            </p>
          </center>
        )}
        {!data.fetching &&
          comments &&
          comments.length > 0 &&
          comments.map((comment) => {
            return (
              <Card key={comment.id} className="mb-2">
                <Card.Header>
                  <Card.Title>
                    <Image
                      style={{
                        border: "1px solid black",
                        borderRadius: "100%",
                      }}
                      height="30"
                      src={
                        comment.avatar
                          ? comment.avatar
                          : "/static/media/ImagePlaceholder.png"
                      }
                    />
                    &nbsp;{comment.username}
                    <span style={{ float: "right" }}>
                      {commentEditPermissionLogic(comment, profile) && (
                        <CommentEditorModal
                          comment_body={comment.body}
                          comment={comment}
                          post_id={post_id}
                          handleUpdateCommentList={(comment) =>
                            handleUpdateCommentList(comment)
                          }
                        />
                      )}
                      &nbsp;
                      {commentDeletePermissionLogic(comment, profile) && (
                        <CommentDeleteModal
                          comment={comment}
                          post_id={post_id}
                          set_comments_count={props.set_comments_count}
                          commentListRemover={(comment_id) =>
                            commentListRemover(comment_id)
                          }
                        />
                      )}
                    </span>
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  <p>{comment.body.split("\n").slice(0, 3).join("\n")}</p>
                  <small>
                    {context.t("Commented: ")} {moment(comment.date).fromNow()}
                  </small>
                </Card.Body>
                {/* <Card.Footer>
             <Button variant="outline-primary" 
               onClick={onCommentLikeClick.bind(this)}
               ><AiFillLike/>
               &nbsp;
              {context.t('Like')}</Button>
            </Card.Footer> */}
              </Card>
            );
          })}
      </Modal.Body>
      <Modal.Footer>
        <Form.Control
          type="text"
          onChange={handleInputChange}
          ref={commentRef}
          onClick={commentInputFocus.bind(this)}
          placeholder={context.t("Write a comment...")}
        />
        <Button
          onClick={postComment.bind(this, post_id)}
          variant="success"
          disabled={comment != "" ? false : true}
        >
          {context.t("Post comment")}
        </Button>
        <Button variant="danger" onClick={closeButtonClick}>
          {context.t("Close")}
        </Button>
      </Modal.Footer>
      <div ref={scrollRef}></div>
    </Modal>
  );
}

CommentModal.contextTypes = {
  t: PropTypes.func.isRequired,
};
