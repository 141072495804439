import React, { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from "react-redux";

import { Container, Row, Col } from 'react-bootstrap';
import ListGroup from 'react-bootstrap/ListGroup'
import BaseView from '../BaseView';

import PollsListItem from './PollsListItem';

import usePollSearch from "../../common/hooks/usePollSearch";

export default function PollsListView(props, context) {
  // Query and Cursor
  const [query, setQuery] = useState('')
  const [cursor, setCursor] = useState('')

  //resolve duplicated from searching query
  const [uniqeIds, setUniqeIds] = useState([])

  const { next, polls, hasMore, loading, error } = usePollSearch(query, cursor)

  const observer = useRef()

  const lastElementRef = useCallback(node => {
    if (loading) return

    if (observer.current) observer.current.disconnect()

    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore && next.current) {
        setCursor(next.current)
      }
    })

    if (node) observer.current.observe(node)
  }, [loading, hasMore])

  useEffect(() => {
    const uniqueIds = Array.from(new Set(polls.map(p => p.id)))
      .map(id => {
        return polls.find(r => r.id === id)
      })
    setUniqeIds(uniqueIds)
  }, [polls])

  return (
    <BaseView title={context.t('Polls')} backurl='/'>
      <hr className='afterTopNavigationBar'></hr>
        <Container>
          <Row>
          {uniqeIds.map((item, index) => {
            if (uniqeIds.length === index + 1) {
              return (
                <Col key={index} xs={12} lg={4} style={{marginBottom: '8px'}} ref={lastElementRef}>
                  <PollsListItem data={polls[index]} />
                </Col>
              )
            } else {
              return (
                <Col key={index} xs={12} lg={4} style={{marginBottom: '8px'}}>
                  <PollsListItem data={polls[index]} />
                </Col>
              )
            }})}
            </Row>
        </Container>
        <Container style={{ marginTop: '1em' }}>
          {loading ? context.t('Loading..') : ''}
        </Container>
    </BaseView>
  );
}

PollsListView.contextTypes = {
  t: PropTypes.func.isRequired
}
