import React, { useState, useEffect, useRef } from "react";
import { NavLink, Link, useParams, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { Navbar, Col } from 'react-bootstrap';
import { Online } from "react-detect-offline";
import {handleNavigate} from '../common/utilities/navigations'


export default function TopNavigationBar(props) {
  const history = useHistory()
  const location = useLocation()

  const lang = useSelector(state => state.i18nState.lang)

  const [prev, setPrev] = useState(null)

  useEffect(() => {
    let params = new URLSearchParams(location.search)
    let prev = params.get('prev')
    setPrev(prev)
  }, [])

  return (
    <Navbar className='TopNavigationBar'>
      <Col xs={2}>
        <Link to={prev || props.backurl || '/'}
              onClick={handleNavigate}
              style={{visibility: !props.backurl && 'hidden'}}
        >
          <i className='fas fa-arrow-circle-left fa-lg'></i>
        </Link>
      </Col>
      <Col xs={8}>
        <h3 style={{wordBreak: 'break-word'}}>{props.title}</h3>
      </Col>
      <Col xs={2}>
        {/* <Online>
          <NavLink to={props.isAuthenticated? '/profile' : '/login'}><i className="fas fa-user-circle fa-lg"></i></NavLink>
        </Online> */}
      </Col>
    </Navbar>
  )
}
