import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types'
import Select from 'react-select';
import { readSkillLevels } from '../../common/utilities/utilities'

export default function ReadLevelSelect(props,context) {

    const options = readSkillLevels(context)

    const [selectedOption, setSelectedOption] = useState(null)

    const handleChangeReadLevel = (option) => {
        setSelectedOption(option)
        if (props.onChange) {
            props.onChange(option.value)
        }
    }

    useEffect(() => {
        setSelectedOption(props.initialValue)
    }, [props.initialValue])

    return (
        <div>
            <Select
                name="read_level"
                value={
                    options.filter(option => option.value == selectedOption)
                }
                onChange={handleChangeReadLevel}
                options={options}
            />
        </div >
    );
}

ReadLevelSelect.contextTypes = {
    t: PropTypes.func.isRequired
}

