import React, { useState } from "react";
import { Modal, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { AiOutlineDelete } from "react-icons/ai";
import PropTypes from "prop-types";
import axios from "axios";
import { toast } from "react-toastify";

function CommentDeleteModal(props, context) {
  const [showModal, setShowModal] = useState(false);
  const comment = props.comment;
  const post_id = props.post_id;
  const commentListRemover = props.commentListRemover;

  const handleDelete = (post_id, e) => {
    // Call the onDelete function passed as a prop
    e.preventDefault();
    const formData = new FormData();
    formData.append("comment_id", comment.id);

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/discussion-forum/posts/${post_id}/remove-comment/`,
        formData
      )
      .then((response) => {
        commentListRemover(comment.id);
        toast.success(context.t("Comment Deleted !"));
        handleClose();
      })
      .catch((error) => {
        console.error("Error deleting post:", error);
      });
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleShow = (e) => {
    e.preventDefault();
    setShowModal(true);
  };

  const renderTooltip = (props) => (
    <Tooltip id="my-tooltip" {...props}>
      {context.t("Delete comment")}
    </Tooltip>
  );

  return (
    <>
      <OverlayTrigger placement="top" overlay={renderTooltip}>
        <Button
          size="sm"
          variant="outline-danger"
          onClick={handleShow.bind(this)}
        >
          <AiOutlineDelete /> Delete Comment
        </Button>
      </OverlayTrigger>

      <Modal centered show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{context.t("Delete Comment")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {context.t("Are you sure you want to delete this comment?")}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {context.t("Cancel")}
          </Button>
          <Button variant="danger" onClick={handleDelete.bind(this, post_id)}>
            {context.t("Delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CommentDeleteModal;

CommentDeleteModal.contextTypes = {
  t: PropTypes.func.isRequired,
};
