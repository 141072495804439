export const reactSelectDropdownStyle = {
  control: base => ({
      ...base,
      minHeight: 30
  }),
  dropdownIndicator: base => ({
      ...base,
      padding: 4
  }),
  clearIndicator: base => ({
      ...base,
      padding: 4
  }),
  valueContainer: base => ({
      ...base,
      padding: '0px 6px'
  }),
  input: base => ({
      ...base,
      margin: 0,
      padding: 0
  })
};

export const reactSelectDropdownStyleLeft = {
  ...reactSelectDropdownStyle,
  menu: base => ({
    ...base,
    position: 'absolute',
    left: 0,
    width: 'calc(200% + 30px)',
  }),
};

export const reactSelectDropdownStyleRight = {
  ...reactSelectDropdownStyle,
  menu: base => ({
    ...base,
    position: 'absolute',
    right: 0,
    width: 'calc(200% + 30px)',
  }),
};

export const groupedStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingBottom: '6px',
  borderBottom: 'solid 1px black',
  fontWeight: 700,
  color: 'black'
};

export const groupedBadgeStyle = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
};
