import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from "react-redux";

import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom'
import { Container, Image, Button } from 'react-bootstrap';

import { Online } from "react-detect-offline";

import BaseView from '../BaseView';
import Comment from '../../components/Comment';
import CommentInput from '../../components/CommentInput';
import ShareButtons from '../../components/ShareButtons';
import { getGuides } from '../../common/redux/actions/GuidesActions';
import { getGuideCategories } from '../../common/redux/actions/GuideCategoriesActions';
import { postComment, deleteComment } from '../../common/redux/actions/CommentsActions';

import { addDefaultImageSrc } from '../../common/utilities/utilities';


export default function GuidesDetailedView(props, context) {
  const dispatch = useDispatch()

  const profile = useSelector(state => state.ProfileState)
  const guides = useSelector(state => state.GuidesState)
  const guideCategories = useSelector(state => state.GuideCategoriesState)

  const [guide, setGuide] = useState(null)
  const [commentReply, setCommentReply] = useState({
    show: false,
    parent: null,
    base_parent: null,
    username: '',
    isModerator: false
  })

  useEffect(() => {
    !guides.fetched && dispatch(getGuides())
    !guideCategories.fetched && dispatch(getGuideCategories())
  }, []);

  useEffect(() => {
    if (guides.fetched) {
      const guideId = parseInt(props.match.params.id)

      const matchedGuide = guides.items.filter(guide => guide.id === guideId)[0]
      if (matchedGuide) {
        setGuide(matchedGuide)
      } else {
        props.history.push('/not-found')
      }
    }
  }, [guides.items]);

  const handleSubmitComment = (payload) => {
    dispatch(postComment('guide', payload))
  }

  const handleClickReplyButton = (replyPayload) => {
    setCommentReply({
      ...replyPayload,
      show: true,
    })
  }

  const handleCloseReply = () => {
    setCommentReply({
      show: false,
      parent: null,
      base_parent: null,
      username: '',
      isModerator: false
    })
  }

  return (
    <BaseView title={context.t('Guides')} backurl='/guides' >
      {guides.loading || guideCategories.loading?
        <div style={{display:'flex', height:'calc(100% - 118px)'}}>
          <img src={'/static/media/spinner.png'} alt='' className='LoadingSpinner'/>
        </div>
        :
        guide &&
          <Container className='afterTopNavigationBar' style={{padding:0}}>
            <Image
              src={guide.image? guide.image : '/static/media/guide_news.jpg'}
              crossOrigin='anonymous'
              style={{width:'inherit'}}
              onError={addDefaultImageSrc}
            />
            <Container className='pb-6' style={{textAligh:'left !important', overflow:'hidden'}}>
              {profile.data && profile.data.type === 'IS' &&
                <Button
                  href={`${process.env.REACT_APP_SERVER_URL}/admin/guides/guide/${guide.id}/change`}
                  target='_blank'
                  className='mt-3'
                  style={{backgroundColor: 'teal', border: 'none'}}> Change in CMS
                </Button>
              }
              <h3 className='mt-4'>{guide.title}</h3>
              <div className='description' dangerouslySetInnerHTML={{__html: guide.description}}/>
              <hr/>
              {guide.audio &&
                <audio
                  src={guide.audio}
                  controls>
                </audio>
              }
              {guide.video &&
                <video
                  src={guide.video}
                  width="320"
                  height="240"
                  controls>
                </video>
              }
              {guide.comments &&
                <div className='CommentThread'>
                  {guide.comments.sort(
                    (a,b) => new Date(b.created_at) - new Date(a.created_at)
                  ).map((comment, index) => {
                    return <Comment
                              key={index}
                              comment={comment}
                              onClickReply={handleClickReplyButton}
                              onSubmitReply={handleSubmitComment}
                            />
                  })}
                </div>
              }
              <Online>
                {profile.data && profile.data.id ?
                  <CommentInput
                    user_id={profile.data.id}
                    object_id={guide.id}
                    parent={commentReply.parent}
                    base_parent={commentReply.base_parent}
                    isReply={commentReply.show}
                    onSubmit={handleSubmitComment}
                    onCloseReply={handleCloseReply}
                    replyUsername={commentReply.username}
                    replyIsModerator={commentReply.isModerator}
                  />
                  :
                  <div className='mt-3'>
                    <NavLink to='/login'> {context.t('Please login to write a comment..')} </NavLink>
                  </div>
                }
              </Online>
              <div className='mt-3'>
                <ShareButtons title={guide.title} />
              </div>
            </Container>
          </Container>
        }
    </BaseView>
  );
}

GuidesDetailedView.contextTypes = {
  t: PropTypes.func.isRequired
}
