import React from "react";
import { useState, useEffect } from "react";
import "./Maintenance.css";
import maintenance_icon from "../Images/maintenance.png";

const Maintenance = () => {
  const [messages, setMessage] = useState([
    {
      message:
        "गोल्डेन ड्रीम्स मर्मतसम्भारमा छ, तर २ घण्टामा फिर्ता हुनेछ, कुनै असुविधाको लागि माफ गर्नुहोस्। धन्यवाद",
      thanks: "धन्यवाद!",
      duration: 5000,
    },
    {
      message:
        "နည်းပညာပိုင်းဆိုင်ရာ ပြုပြင်ထိန်းသိမ်းမှုများကို လတ်တလော  လုပ်ဆောင်နေပါသဖြင့် 'ရွှေအိပ်မက်' ဖုန်းအပလီကေးရှင်း၏ လုပ်ဆောင်ချက်များ ခေတ္တရပ်ဆိုင်းနေပါမည်။  ပုံမှန်လုပ်ဆောင်ချက်များ ၂ နာရီအတွင်း ပြန်လည်ရရှိပါမည်။ အဆင်မပြေမှုများအတွက် နားလည်ပေးပါရန် လေးစားစွာ မေတ္တာရပ်ခံအပ်ပါသည်။",
      thanks: "ကျေးဇူးတင်ပါသည်!",
      duration: 15000,
    },
    {
      message:
        "សូមអភ័យទោសចំពោះការរអាក់រអួលការប្រើប្រាស់ កម្មវិធីសុបិន្ត ឥស្សរា(Golden Dreams) កំពុងស្ថិតក្រោមការត្រួតពិនិត្យបច្ចេកទេស ប៉ុន្តែនឹងដំណើរការជាធម្មតាវិញក្នុងរយៈពេល 2 ម៉ោងទៀត ។",
      thanks: "សូមអរគុណ",
      duration: 5000,
    },
    {
      message:
        "Golden Dreams is under maintenance, but will be back in 2 hours, sorry for any inconvenience.",
      thanks: "Thank you!",
      duration: 3500,
    },
    {
      message:
        "နည္းပညာပိုင္းဆိုင္ရာ ျပဳျပင္ထိန္းသိမ္းမႈမ်ားကို လတ္တေလာ  လုပ္ေဆာင္ေနပါသျဖင့္ 'ေ႐ႊအိမ္မက္' ဖုန္းအပလီေကးရွင္း၏ လုပ္ေဆာင္ခ်က္မ်ား ေခတၱရပ္ဆိုင္းေနပါမည္။  ပုံမွန္လုပ္ေဆာင္ခ်က္မ်ား ၂ နာရီအတြင္း ျပန္လည္ရရွိပါမည္။ အဆင္မေျပမႈမ်ားအတြက္ နားလည္ေပးပါရန္ ေလးစားစြာ ေမတၱာရပ္ခံအပ္ပါသည္။ ",
      thanks: "ေက်းဇူးတင္ပါသည္!",
      duration: 15000,
    },
  ]);

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % messages.length);
    }, messages[currentIndex].duration);

    return () => clearInterval(interval);
  }, [messages, currentIndex]);

  return (
    <div id="maintenance-container">
      <img id="maintenance-icon" src={maintenance_icon} />
      <h1 id="maintenance-message">{messages[currentIndex].message}</h1>
      <h2 id="maintenance-thanks">{messages[currentIndex].thanks}</h2>
    </div>
  );
};

export default Maintenance;
