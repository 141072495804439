import React, { Component } from 'react';
import PropTypes from 'prop-types'

import { connect } from 'react-redux';

import BaseView from './BaseView';
import JobseekersProfileView from './Jobseekers/JobseekersProfileView';
import IssaraStaffProfileView from './IssaraStaff/IssaraStaffProfileView';
import { authLogout } from '../common/redux/actions/AuthenticationActions';


class ProfileView extends Component {


  render() {

    const profile = () => {
      if (this.props.profile.type === 'JS') {
        return <JobseekersProfileView {...this.props} />
      }
      else if (this.props.profile.type === 'IS') {
        return <IssaraStaffProfileView {...this.props} />
      }
    }

    return (
      <BaseView title={this.context.t('Profile')} backurl='/' >
        <hr className='afterTopNavigationBar'></hr>
        {profile()}
      </BaseView>
    )
  }
}

ProfileView.contextTypes = {
  t: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  return {
    lang: state.i18nState.lang,
    isAuthenticated: state.AuthenticationState.token !== null,
    profile: state.ProfileState.data,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    authLogout: () => dispatch(authLogout()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileView);
