import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import axios from "axios";

import BaseView from "../BaseView";
import ProblemListItem from "./ProblemListItem";
import ProblemMessage from "./ProblemMessage";
import MessageInput from "../../components/MessageInput";

import {
  getProblems,
  postMessage,
} from "../../common/redux/actions/ProblemsActions";

export default function DetailedProblemView(props, context) {
  const dispatch = useDispatch();
  const scrollRef = useRef();
  const [problemItem, setProblemItem] = useState(null);
  const [problemsArray, setProblemsArray] = useState([]);
  const profile = useSelector((state) => state.ProfileState.data);
  const problems = useSelector((state) => state.ProblemsState);

  const [payload, setPayload] = useState({
    problem_id: null,
    message: "",
  });

  useEffect(() => {
    if (profile.type === "IS") {
      !problems.fetched && !problems.loading && dispatch(getProblems());
    }
  }, []);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
  }, [problemItem]);

  useEffect(() => {
    if (profile.id) {
      if (profile.type === "IS") {
        if (problems.fetched) {
          setProblemsArray(problems.items);
        }
      } else {
        axios
          .get(`${process.env.REACT_APP_API_V2_URL}/problems`)
          .then((response) => {
            if (response.data.length > 0) {
              setProblemsArray(response.data);
            }
          })
          .catch((error) => {
            console.error("Error fetching problems:", error);
          });
      }
    }
  }, [profile, problems]);

  useEffect(() => {
    if (problemsArray.length > 0) {
      const problemId = parseInt(props.match.params.id);
      const matchedProblem = problemsArray.find(
        (problem) => problem.id === problemId
      );

      if (matchedProblem) {
        setProblemItem(matchedProblem);
        setPayload((prevPayload) => ({
          ...prevPayload,
          problem_id: matchedProblem.id,
        }));
      } else {
        props.history.push("/not-found");
      }
    }
  }, [problemsArray, props.match.params.id]);

  const handleSendMessage = () => {
    dispatch(postMessage(payload));
    setPayload({ ...payload, message: "" });
  };

  const handleChangeMessage = (event) => {
    setPayload({ ...payload, message: event.target.textContent });
  };

  return (
    <BaseView title={context.t("Share a problem")} backurl="/problems">
      <Container
        className="afterTopNavigationBar"
        style={{
          minHeight: "100vh",
          height: "auto",
          backgroundColor: "#e9ecef",
        }}
      >
        {problemItem && (
          <div>
            <div
              style={{
                position: "relative",
                top: 10,
                boxShadow: "4px 6px 6px -5px rgb(0 0 0 / 17%)",
              }}
            >
              <ProblemListItem problem={problemItem} pointerDisabled={true} />
            </div>
            <div
              className="mt-4"
              style={{ display: "flex", flexDirection: "column" }}
            >
              {Array.isArray(problemItem.messages) &&
                problemItem.messages.length > 0 &&
                problemItem.messages.map((message, key) => (
                  <ProblemMessage message={message} key={key} />
                ))}
            </div>
            {problemItem.status !== "RESOLVED" && (
              <MessageInput
                onChangeMessage={handleChangeMessage}
                onSendMessage={handleSendMessage}
                placeholder={context.t("Add a reply...")}
                value={payload.message}
              />
            )}
            <div ref={scrollRef} style={{ paddingBottom: "70px" }}></div>
          </div>
        )}
      </Container>
    </BaseView>
  );
}

DetailedProblemView.contextTypes = {
  t: PropTypes.func.isRequired,
};
