const fullNameValidator = (full_name) => {
  if (full_name.length === 0) {
    return "Name is required";
  }
};

const usernameValidator = (username) => {
  if (username.length === 0) {
    return "Username is required";
  } else if (username.length <= 7) {
    return "Username should be at least 8 letters long";
  } else if (username.length >= 12) {
    return "Username can't be longer than 12 letters";
  }
};

const passwordValidator = (password) => {
  if (password.length === 0) {
    return "Password is required";
  } else if (password.length <= 7) {
    return "Password should be at least 8 letters long";
  } else if (password.length >= 12) {
    return "Password can't be longer than 12 letters";
  }
};

const phoneNumberValidator = (phone_number, isMalay = false) => {
  switch (true) {
    case phone_number.startsWith("0") && isMalay:
      break;
    case phone_number.startsWith("0") && !isMalay:
      return "Please do not start phone number with leading 0 or +!";
    case phone_number.startsWith("+"):
      return "Please do not start phone number with leading 0 or +!";
    default:
  }
};

const validators = {
  full_name: fullNameValidator,
  username: usernameValidator,
  password: passwordValidator,
  phone_number: phoneNumberValidator,
};

const validateInput = (name, value, country_code) => {
  try {
    if (name === "phone_number") {
      return validators[name](
        value,
        country_code === "+60" || country_code === 60 ? true : false
      );
    }
    return validators[name](value);
  } catch (err) {
    console.log(`${name} doesn't require validation`);
  }
};

export { validateInput };
