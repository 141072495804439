import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import axios from "axios";

export default function useRecruitmentAgencySearch(query, cursor) {
  const language = useSelector((state) => state.i18nState.lang);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [recruiters, setRecruiters] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const next = useRef(
    `${process.env.REACT_APP_API_V2_URL}/recruitment-agencies?lang=${language}`
  );

  const buildQueryParams = (params) => {
    const queryStrings = [];

    if (params.sort && params.sort !== "") {
      queryStrings.push(`ordering=${params.sort}`);
    }
    if (params.filter && params.filter !== "") {
      queryStrings.push(`country=${params.filter}`);
    }
    if (params.search && params.search !== "") {
      queryStrings.push(`search=${params.search}`);
    }

    return queryStrings.length > 0 ? `&${queryStrings.join("&")}` : "";
  };

  useEffect(() => {
    setRecruiters([]);
    let baseUrl = `${process.env.REACT_APP_API_V2_URL}/recruitment-agencies?lang=${language}`;

    if (query) {
      const queryParams = buildQueryParams(query);
      next.current = `${baseUrl}${queryParams}`;
    } else {
      next.current = baseUrl;
    }
  }, [query, language]);

  useEffect(() => {
    let cancel;
    setError(false);
    setLoading(true);

    axios({
      method: "GET",
      url: next.current,
      cancelToken: new axios.CancelToken((c) => (cancel = c)),
    })
      .then((res) => {
        setError(false);
        setLoading(false);
        let recruiters = [];
        Object.keys(res.data.results).map((key) => {
          recruiters.push(res.data.results[key]);
        });

        setRecruiters((prevRecruiters) => [...prevRecruiters, ...recruiters]);

        if (res.data.next) {
          setHasMore(true);
          next.current = res.data.next;
        } else {
          setHasMore(false);
        }
      })
      .catch((e) => {
        if (axios.isCancel(e)) return;
        setError(true);
      });

    return () => cancel();
  }, [query, cursor]);

  return { next, loading, error, recruiters, hasMore };
}
